const Reducer = (state, action) => {
switch(action.type){
case "CATEGORY_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "CATEGORY_SET":
return {
...state,
isLoading: false,
isError: false,
categoryData: action.payload,
}
case "CATEGORY_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "PRODUCT_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "PRODUCT_SET":
return {
...state,
isLoading: false,
isError: false,
productData: action.payload,
}
case "PRODUCT_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "COUPON_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "COUPON_SET":
return {
...state,
isLoading: false,
isError: false,
couponData: action.payload,
}
case "COUPON_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "TAX_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "TAX_SET":
return {
...state,
isLoading: false,
isError: false,
taxData: action.payload,
}
case "TAX_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "ORDERS_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "ORDERS_SET":
return {
...state,
isLoading: false,
isError: false,
allOrders: action.payload,
}
case "ORDERS_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "ORDERS_PRD_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "ORDERS_PRD_SET":
return {
...state,
isLoading: false,
isError: false,
orderPrd: action.payload,
}
case "ORDERS_PRD_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "REMOVE_PRD_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "REMOVE_PRD_SET":
return {
...state,
isLoading: false,
isError: false,
allRemove: action.payload,
}
case "REMOVE_PRD_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "REMOVE_ITEM_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "REMOVE_ITEM_SET":
return {
...state,
isLoading: false,
isError: false,
itemRemove: action.payload,
}
case "REMOVE_ITEM_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "TABELS_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "TABELS_SET":
return {
...state,
isLoading: false,
isError: false,
allTabels: action.payload,
}
case "TABELS_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "CUSTOMER_LOADING":
return {
...state,
isLoading: true,
isError: false,
}
case "CUSTOMER_SET":
return {
...state,
isLoading: false,
isError: false,
customers: action.payload,
}
case "CUSTOMER_ERROR":
return {
...state,
isLoading: false,
isError: true,
}
case "ADMIN_DATA_LOADING":
return {
...state,
isUserLoading: true,
isUserError: false,
}
case "ADMIN_DATA_SET":
return {
...state,
isUserLoading: false,
isUserError: false,
adminData: action.payload,
}
case "ADMIN_DATA_ERROR":
return {
...state,
isUserLoading: false,
isUserError: true,
}
case "STORE_DATA_LOADING":
return {
...state,
isUserLoading: true,
isUserError: false,
}
case "STORE_DATA_SET":
return {
...state,
isUserLoading: false,
isUserError: false,
storeInfo: action.payload,
}
case "STORE_DATA_ERROR":
return {
...state,
isUserLoading: false,
isUserError: true,
}
case "STORE_GST_DATA_LOADING":
return {
...state,
isUserLoading: true,
isUserError: false,
}
case "STORE_GST_DATA_SET":
return {
...state,
isUserLoading: false,
isUserError: false,
storeGst: action.payload,
}
case "STORE_GST_DATA_ERROR":
return {
...state,
isUserLoading: false,
isUserError: true,
}
case "STORE_SOCIAL_DATA_LOADING":
return {
...state,
isUserLoading: true,
isUserError: false,
}
case "STORE_SOCIAL_DATA_SET":
return {
...state,
isUserLoading: false,
isUserError: false,
storeSocial: action.payload,
}
case "STORE_SOCIAL_DATA_ERROR":
return {
...state,
isUserLoading: false,
isUserError: false,
}

default: return state;

}
}

export default Reducer;



