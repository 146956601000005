import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const AddTabel = () => {

const CloseModal = () => {
$('#addTabelModal').hide();
}

const submitTabel = async() => {
var name = $('#tabelName').val();
var tabelNo = $('#tabelNumber').val();
var status = $('#tabStat').val();

if (name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tabel name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if (tabelNo === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tabel number required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if (status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/add-tabel', {name: name, tabelNo: tabelNo, status: status});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Tabel created successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;    
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend add-tabel-api error: ' + error);  
}
}
}

const handleNum = (e) => {
if(e.target.value < 1) {
$('#tabelNumber').val('1');  
}  
}

return (
<>
<div className='modals' id='addTabelModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-plus'></i> Add Table <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='input-div'>
<label>Tabel Name :</label>
<input type='text' id='tabelName' placeholder='Enter tabel name...' />
</div>
<div className='input-div'>
<label>Tabel Number :</label>
<input type='number' id='tabelNumber' placeholder='Enter tabel number...' onChange={handleNum} />
</div>
<div className='input-div'>
<label>Status :</label>
<select id='tabStat'>
<option value='null'>---select status---</option>
<option value='active'>Active</option>
<option value='inactive'>In-Active</option>
</select>
</div>
<div className='input-div-submit'>
    <button onClick={() => submitTabel()}><i className='fa fa-plus'></i> Add Tabel</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default AddTabel;
