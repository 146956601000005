import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const AddCustomer = () => {

const CloseModal = () => {
$('#addUserModal').hide();
}

const submitCustomer = async() => {
var name = $('#customerName').val();
var contact = $('#customerContact').val();
var email = $('#customerEmail').val();
var city = $('#customerCity').val();
var state = $('#customerState').val();
var pincode = $('#customerPincode').val();
// alert(name +' -- '+ contact +' -- '+ email +' -- '+ city +' -- '+ state +' -- '+ pincode)
if (name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Customer name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if (contact.length < 10 || contact.length > 10){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('10 digit contact required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/createAdmin-customer', {name: name, contact: contact, email: email, city: city, state: state, pincode: pincode});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Customer created successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend create-customer-api error: ' + error);
}
}
}

return (
<>
<div className='modals' id='addUserModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-plus'></i> Add Customer <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='input-div'>
<label>Customer Name :</label>
<input type='text' id='customerName' placeholder='Enter customer name...' />
</div>
<div className='input-div'>
<label>Contact :</label>
<input type='number' id='customerContact' placeholder='Enter contact number...' />
</div>
<div className='input-div'>
<label>Email :</label>
<input type='email' id='customerEmail' placeholder='Enter email id...' />
</div>
<div className='input-div'>
<label>City :</label>
<input type='text' id='customerCity' placeholder='Enter city name...' />
</div>
<div className='input-div'>
<label>State :</label>
<input type='text' id='customerState' placeholder='Enter state name...' />
</div>
<div className='input-div'>
<label>Pin Code :</label>
<input type='number' id='customerPincode' placeholder='Enter area pin code...' />
</div>
<div className='input-div-submit'>
<button onClick={() => submitCustomer()}><i className='fa fa-plus'></i> Add Customer</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default AddCustomer;
