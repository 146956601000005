import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';

const Profile = () => {

const { adminData } = GlobalData();

const changePassAdmin = async() =>{
var id = $('#adminId').val();
var Pass = $('#userPass').val();
var oldPass = $('#userOldPass').val();
var newPass = $('#userNewPass').val();
var ConfPass = $('#userConfPass').val();
if (oldPass === '' || newPass === '' || ConfPass === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('All fields are required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(Pass !== oldPass){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Old password wrong');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(newPass !== ConfPass){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Confirm password wrong');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/changePass-admin', {id: id, pass: ConfPass});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Change password successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend changeAdmin-password-api error: ' + error);
}
}
}

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Change Password</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / My Account</span>
</div>
</div>

<div className='content-wrap'>

{
adminData.length > 0 && adminData.map((data, index) => {
return (
<div className='card-bodysingle' key={index}>
<div className='input-div'>
<label>Old Password :</label>
<input type='hidden' id='adminId' defaultValue={data.id} />
<input type='hidden' id='userPass' defaultValue={data.password} />
<input type='text' id='userOldPass' placeholder='Enter old password...' />
</div>
<div className='input-div'>
<label>New Password :</label>
<input type='text' id='userNewPass' placeholder='Enter new password...' />
</div>
<div className='input-div'>
<label>Confirm Password :</label>
<input type='text' id='userConfPass' placeholder='Enter confirm password' />
</div>
<div className='input-div-submit'>
<button onClick={() => changePassAdmin()}><i className='fa fa-key'></i> Change Password</button>
</div>
</div>
)
})
}

</div>

</div>
</div>
</>
)
}

export default Profile;
