import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';

const TabelEdit = ({data}) => {

const [getFilter, setGetFilter] = useState([]);

const CloseModal = () => {
$('#editTabelModal').hide();
setGetFilter([]); 
}

const handleEditNum = (e) => {
if (e.target.value < 1){
$('#edittabelNum').val('1');
}
}

const updateTabel = async() => {
var id = $('#updateTabelId').val();
var name = $('#edittabelName').val();
var tabelNo = $('#edittabelNum').val();
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Table name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/update-tabels', {id: id, name: name, tabelNo: tabelNo});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Tabel updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;   
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}
} catch (error) {
console.log('backend tabel-edit-api error: ' + error);
}
}
}

useEffect(() => {
if(data.length > 0){
setGetFilter(data);   
}
},[data]);

return (
<>
<div className='modals' id='editTabelModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-edit'></i> Edit Table <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
{
getFilter.length > 0 && getFilter.map((data, index) => {
let noTab = 0;
noTab = data.tabelNo.split(' ');
return (
<div className='body-content' key={index}>
<div className='input-div'>
<input type='hidden' id='updateTabelId' defaultValue={data.id} />
<label>Tabel Name :</label>
<input type='text' id='edittabelName' placeholder='Enter tabel name...' defaultValue={data.name} />
</div>
<div className='input-div'>
<label>Tabel Number :</label>
<input type='number' id='edittabelNum' placeholder='Enter tabel number...' defaultValue={noTab[2]} onChange={handleEditNum} />
</div>
<div className='input-div-submit'>
<button onClick={() => updateTabel()}><i className='fa fa-edit'></i> Edit Tabel</button>
</div>
</div>
)
})
}
</div>

</div>
</div>
</>
)
}

export default TabelEdit;
