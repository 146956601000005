import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './content/Header';
import LeftSidebar from './content/LeftSidebar';
import Dashboard from './pages/Dashboard';
import AddCategory from './pages/AddCategory';
import AddProduct from './pages/AddProduct';
import ErrorPage from './pages/ErrorPage';
import AddCoupon from './pages/AddCoupon';
import AddTax from './pages/AddTax';
import AllOrders from './pages/AllOrders';
import ViewOrders from './pages/ViewOrders';
import OrdersRemove from './pages/OrdersRemove';
import ViewRemove from './pages/ViewRemove';
import Transation from './pages/Transation';
import Invoice from './pages/Invoice';
import Tables from './pages/Tables';
import Customers from './pages/Customers';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Reports from './pages/Reports';
import AutoScroll from './content/AutoScroll';
import SettingContact from './pages/SettingContact';
import SettingGst from './pages/SettingGst';
import SettingSocial from './pages/SettingSocial';
import Auth from './content/Auth';

const App = () => {

return <BrowserRouter>
<Auth />
<AutoScroll />
<Header />
<LeftSidebar />
<Routes>
<Route path='/' element={<Dashboard />} />
<Route path='/add-category' element={<AddCategory />} />
<Route path='/add-product' element={<AddProduct />} />
<Route path='/add-coupon' element={<AddCoupon />} />
<Route path='/add-tax' element={<AddTax />} />
<Route path='/all-orders' element={<AllOrders />} />
<Route path='/view-orders/:id' element={<ViewOrders />} />
<Route path='/remove-orders/' element={<OrdersRemove />} />
<Route path='/view-remove/:id' element={<ViewRemove />} />
<Route path='/all-transation/' element={<Transation />} />
<Route path='/all-invoice/' element={<Invoice />} />
<Route path='/all-tabels/' element={<Tables />} />
<Route path='/all-customer/' element={<Customers />} />
<Route path='/user-profile/' element={<Profile />} />
<Route path='/all-reports/' element={<Reports />} />
<Route path='/setting-contact/' element={<SettingContact />} />
<Route path='/setting-gst/' element={<SettingGst />} />
<Route path='/setting-social/' element={<SettingSocial />} />
<Route path='/login/' element={<Login />} />
<Route path='*' element={<ErrorPage />} />
</Routes>
</BrowserRouter>

}

export default App;

