import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';

const EditCustomer = ({data}) => {

const [getFilter, setGetFilter] = useState([]);

const CloseModal = () => {
$('#editUserModal').hide(); 
setGetFilter([]); 
}

const updateCustomer = async() => {
var id = $('#editId').val();
var name = $('#edit_customerName').val();
var contact = $('#edit_customerContact').val();
var email = $('#edit_customerEmail').val();
var citym = $('#edit_customerCity').val();
var state = $('#edit_customerState').val();
var pincode = $('#edit_customerPincode').val();
var status = $('#editCustomStatus').val();

if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Customer name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(contact.length < 10 || contact.length > 10){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('10 digit contact required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/editAdmin-customer', {id: id, name: name, contact: contact, email: email, citym: citym, state: state, pincode: pincode, status: status});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Account updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend edit-customer-api error: ' + error);  
}
}
}

useEffect(() => {
if (data.length > 0){
setGetFilter(data);    
}
},[data]);

return (
<>
<div className='modals' id='editUserModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-edit'></i> Edit Customer <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
{
getFilter.length > 0 && getFilter.map((data, index) => {
return (
<div className='body-content' key={index}>
<div className='input-div'>
<label>Customer Name :</label>
<input type='hidden' id='editId' defaultValue={data.id} />
<input type='text' id='edit_customerName' defaultValue={data.name} placeholder='Enter customer name...' />
</div>
<div className='input-div'>
<label>Contact :</label>
<input type='number' id='edit_customerContact' defaultValue={data.contact} placeholder='Enter contact number...' />
</div>
<div className='input-div'>
<label>Email :</label>
<input type='email' id='edit_customerEmail' defaultValue={data.email} placeholder='Enter email id...' />
</div>
<div className='input-div'>
<label>City :</label>
<input type='text' id='edit_customerCity' defaultValue={data.citym} placeholder='Enter city name...' />
</div>
<div className='input-div'>
<label>State :</label>
<input type='text' id='edit_customerState' defaultValue={data.state} placeholder='Enter state name...' />
</div>
<div className='input-div'>
<label>Pin Code :</label>
<input type='number' id='edit_customerPincode' defaultValue={data.pincode} placeholder='Enter area pin code...' />
</div>
<div className='input-div'>
<label>Status</label>
<select id='editCustomStatus'>
<option value={data.status}>{data.status}</option>
<option value='null'>---select status---</option>
<option value='active'>Active</option>
<option value='inactive'>In-Active</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => updateCustomer()}><i className='fa fa-edit'></i> Update Customer</button>
</div>
</div>
)
})
}
</div>

</div>
</div>
</>
)
}

export default EditCustomer;
