import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';

const AddMore = () => {
const { categoryData, productData } = GlobalData(); 
const [items, setItems] = useState([]);
const [selected, setSelected] = useState([]);

const CloseModal = () => {
$('#addMoreModal').hide();   
}

const handleCat = (name) => {
setItems(productData.filter((item) => item.category === name));
}

const handleChecks = (e) => {
const {value, checked} = e.target;
if(checked){
setSelected([...selected, value]);
}
else{
setSelected(selected.filter((e) => e !== value));    
}
}

const addItemMore = () => {
var category = $('#addCat').val();
var orderId = $('#orderId_Item').val();
var user = $('#order_user').val();
var contact = $('#order_contact').val();
var tabelNo = $('#order_tabel').val();
var payOpt = 'tabel_order';
var dateTime = $('#order_date').val();

if (category === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Category Required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
const dataFilter = items.filter((e) => selected.some((b) => e.id === parseInt(b)));
//console.log(dataFilter);
if(dataFilter.length > 0){
dataFilter.forEach(async e => {
var id = e.id;
var itemName = e.name;
var itemTypes = e.types;
var itemPrice = e.price;
var currPrc = itemPrice;
var itemQuantity = 'full';
var itemStock = 1;

try {
const sendData = await axios.post('/node-server/direct-orders', {
orderId: orderId,
id: id,
itemName: itemName,
itemTypes: itemTypes,
itemPrice: itemPrice,
currPrc: currPrc,
itemQuantity: itemQuantity,
itemStock: itemStock,
tabelNo: tabelNo,
user: user,
contact: contact,
payOpt: payOpt,
dateTime: dateTime
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Item added');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend direct-order-api error: ' + error); 
}
});
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('No items available');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;   
}
}

}

return (
<>
<div className='modals' id='addMoreModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-plus'></i> Add Items <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<input type='hidden' id='orderId_Item' />
<input type='hidden' id='order_user' />
<input type='hidden' id='order_contact' />
<input type='hidden' id='order_tabel' />
<input type='hidden' id='order_date' />
<div className='input-div'>
<label>Select Category</label>
<select onChange={(e) => handleCat(e.target.value)} id='addCat'>
<option value='null'>---select category---</option>
{
categoryData && categoryData.map((data, index) => {
return (
<option key={index} value={data.name}>{data.name}</option>
)   
})
}
</select>
</div>
<div className='input-div-checkBox'>
<span>Items Lists</span>
<hr />
{
items && items.map((data, index) => {
return (
<div key={index}>
<label>
<input type='checkbox' defaultValue={data.id} onChange={(e) => handleChecks(e)} /> {data.name +' (Price : '+ data.price +')'}
</label>
</div>
)
})
}
</div>
<div className='input-div-submit'>
<button onClick={() => addItemMore()}>Add Items</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default AddMore;
