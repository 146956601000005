import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';

const AddCateoryModal = () => {
const [files, setFiles] = useState([]);

const CloseCategoryModal = () => {
$('#addCategoryModal').fadeOut('slow');  
}

const handleFile = (e) => {
setFiles(e.target.files[0]);   
}

const saveCategory = async() => {
var formdata = new FormData();
var name = $('#prdName').val();
var types = $('#prdType').val();

if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Category name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');   
},1000);
return false;
}
if(types === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Item type required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');   
},1000);
return false;
}
if(files.length === 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Category image required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');   
},1000);
return false;
}

formdata.append('name', name);
formdata.append('types', types);
formdata.append('categoryImg', files);

try {
const sendData = await axios.post('/node-server/add-category', formdata, {
headers: {'Content-Type': 'multipart/form-data'}
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Category created successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
window.location.reload(); 
},1000);
return false;    
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);      
}
} catch (error) {
console.log('backend category-create-api error: ' + error);
}
}

return (
<>
<div className='modals' id='addCategoryModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-plus'></i> Add Category <i className='fa fa-close rightModalIcon' onClick={() => CloseCategoryModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='form-dataDiv'>
<div className='input-div'>
<label>Category Image: </label>
<input type='file' onChange={(e) => handleFile(e)} />
</div>
<div className='input-div'>
<label>Category Name: </label>
<input type='text' id='prdName' placeholder='Enter Category Name...' />
</div>
<div className='input-div'>
<label>Item Type</label>
<select id='prdType'>
<option value='null'>---select item type---</option>
<option value='veg'>Veg</option>
<option value='non veg'>Non Veg</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => saveCategory()}><i className='fa fa-plus'></i> Add Category</button>
</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default AddCateoryModal;
