import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ACModal = () => {

const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());

const CloseModal = () => {
$('#addCouponModal').hide();
}

const createCoupon = async() => {
var name = $('#coupon-name').val(); 
var rate = $('#coupon-rate').val(); 
var type = $('#coupon-type').val(); 
var start_Date = startDate;
var end_Date = endDate;  
var status = $('#coupon-status').val();
//alert(name +' -- '+ rate +' -- '+ type +' -- '+ start_Date +' -- '+ end_Date +' -- '+ status);
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
if(rate === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon rate required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
if(type === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon type required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
if(start_Date === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon start date required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
if(end_Date === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon end date required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/add-coupon', {
name: name,
rate: rate,
type: type,
start_Date: start_Date,
end_Date: end_Date,
status: status
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').addClass('danger').html('Create product successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('success').addClass('danger').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}  
} catch (error) {
console.log('backend add-product-api error: ' + error);
}
}
}

return (
<>
<div className='modals' id='addCouponModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-plus'></i> Add Coupon <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='input-div'>
<label>Coupon Name</label>
<input type='text' id='coupon-name' placeholder='Enter coupon name...' />
</div>
<div className='input-div'>
<label>Coupon Rate</label>
<input type='number' id='coupon-rate' placeholder='Enter coupon rate...' />
</div>
<div className='input-div'>
<label>Coupon Type</label>
<select id='coupon-type'>
<option value='null'>--select coupon type--</option>
<option value='percent'>Percent</option>
<option value='fixed'>Fixed</option>
</select>
</div>
<div className='input-div'>
<label>Start Date</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='input-div'>
<label>End Date</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
<div className='input-div'>
<label>Status</label>
<select id='coupon-status'>
<option value='null'>--select status--</option>
<option value='enable'>Enable</option>
<option value='disable'>Disable</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => createCoupon()}><i className='fa fa-plus'></i> Create Coupon</button>
</div>
</div>
</div>

</div>
</div>

</>
)
}

export default ACModal;
