import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';

const SettingContact = () => {

const { storeInfo } = GlobalData();

const updateStore = async() => {
var id = storeInfo.length > 0 && storeInfo[0].id;
var storeContact = $('#storeContact').val();
var storeEmail = $('#storeEmail').val();
var storeStreet = $('#storeStreet').val();
var storeCity = $('#storeCity').val();
var storeState = $('#storeState').val();
var storePostal = $('#storePostal').val();
try {
const sendData = await axios.post('/node-server/update-store', {
id: id,
storeContact: storeContact,
storeEmail: storeEmail,
storeStreet: storeStreet,
storeCity: storeCity,
storeState: storeState,
storePostal: storePostal
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Store updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend store-update-api error: ' + error);
}
}

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Edit Contact Setting</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / Contact Setting</span>
</div>
</div>

<div className='content-wrap'>
{
storeInfo.length > 0 && storeInfo.map((data, index) => {
return (
<div className='card-bodysingle' key={index}>
<div className='input-div'>
<label>Store Contact Number :</label>
<input type='number' id='storeContact' placeholder='Enter store contact number...' defaultValue={data.contact} />
</div>
<div className='input-div'>
<label>Store Email Address :</label>
<input type='text' id='storeEmail' placeholder='Enter store email address...' defaultValue={data.email} />
</div>
<div className='input-div'>
<label>Store Street Address :</label>
<input type='text' id='storeStreet' placeholder='Enter store street address...' defaultValue={data.street} />
</div>
<div className='input-div'>
<label>Store City :</label>
<input type='text' id='storeCity' placeholder='Enter store city...' defaultValue={data.city} />
</div>
<div className='input-div'>
<label>Store State :</label>
<input type='text' id='storeState' placeholder='Enter store state...' defaultValue={data.state} />
</div>
<div className='input-div'>
<label>Store Postal Code :</label>
<input type='number' id='storePostal' placeholder='Enter store postal code...' defaultValue={data.postal} />
</div>
<div className='input-div-submit'>
<button onClick={() => updateStore()}><i className='fa fa-edit'></i> Update Store</button>
</div>
</div>
)
})
}
</div>

</div>
</div>
</>
)
}

export default SettingContact;
