import React from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { GlobalData } from '../context/AppContext';


const LeftSidebar = () => {

const { adminData } = GlobalData();
const Navigate = useNavigate();

const productMenu = () => {
$('#productMenu').toggle();
}
const orderMenu = () => {
$('#orderMenu').toggle(); 
}
const couponMenu = () => {
$('#couponMenu').toggle(); 
}
const taxMenu = () => {
$('#taxMenu').toggle();
}
const closeLeftMenu = () => {
$('#leftMenuSide').hide();
}
const settingMenu = () => {
$('#settingMenu').toggle(); 
}

const orderList = () => {
Navigate('/all-orders');   
$('#leftMenuSide').hide();
$('.side-dropdown').hide();
}

const orderCancel = () => {
Navigate('/remove-orders');   
$('#leftMenuSide').hide();   
$('.side-dropdown').hide();
}

const transation = () => {
Navigate('/all-transation');   
$('#leftMenuSide').hide();    
$('.side-dropdown').hide();
}

const invoicePage = () => {
Navigate('/all-invoice');   
$('#leftMenuSide').hide();  
$('.side-dropdown').hide();    
}

const tabelPage = () => {
Navigate('/all-tabels');   
$('#leftMenuSide').hide();   
$('.side-dropdown').hide();   
}

const customerPage = () => {
Navigate('/all-customer');   
$('#leftMenuSide').hide();    
$('.side-dropdown').hide(); 
}

const reportsPage = () => {
Navigate('/all-reports');   
$('#leftMenuSide').hide();   
$('.side-dropdown').hide(); 
}

const addCat = () => {
Navigate('/add-category');   
$('#leftMenuSide').hide();  
$('.side-dropdown').hide();  
}

const addprdo = () => {
Navigate('/add-product');   
$('#leftMenuSide').hide();
$('.side-dropdown').hide();    
}

const addcou = () => {
Navigate('/add-coupon');   
$('#leftMenuSide').hide();  
$('.side-dropdown').hide();    
}

const addtx = () => {
Navigate('/add-tax');   
$('#leftMenuSide').hide(); 
$('.side-dropdown').hide();     
}

const dashB = () => {
Navigate('/');   
$('#leftMenuSide').hide(); 
$('.side-dropdown').hide();    
}

const gstPage = () => {
Navigate('/setting-gst');   
$('#leftMenuSide').hide(); 
$('.side-dropdown').hide();       
}

const contactPage = () => {
Navigate('/setting-contact');   
$('#leftMenuSide').hide(); 
$('.side-dropdown').hide();    
}

const socialPage = () => {
Navigate('/setting-social');   
$('#leftMenuSide').hide(); 
$('.side-dropdown').hide();     
}

return (
<>
<div className='left-sidebar' id='leftMenuSide'>
<div className='head-sidebar'>
<button onClick={() => closeLeftMenu()}><i className='fa fa-close'></i> Close</button>
</div>

<div className='sideTitle'>
<span><i className='fa fa-long-arrow-right'></i> Log In As : {adminData.length > 0 && adminData[0].username}</span>
</div>

<div className='side-menu'>
<ul>
<li onClick={() => dashB()}><img src='/images/speedometer.gif' alt='side-icon' /> Dashboard</li>
<li onClick={() => productMenu()}><img src='/images/dairy-products.gif' alt='side-icon' /> Products <i className='fa fa-caret-right side-rightIcon'></i></li>
<ul className='side-dropdown' id='productMenu'>
<li onClick={() => addCat()}><img src='/images/add.gif' alt='side-icon' /> Add Category</li>
<li onClick={() => addprdo()}><img src='/images/add.gif' alt='side-icon' /> Add Product</li>
</ul>
<li onClick={() => orderMenu()}><img src='/images/list.gif' alt='side-icon' /> Order <i className='fa fa-caret-right side-rightIcon'></i></li>
<ul className='side-dropdown' id='orderMenu'>
<li onClick={() => orderList()}><img src='/images/view.gif' alt='side-icon' /> Order List</li>
<li onClick={() => orderCancel()}><img src='/images/view.gif' alt='side-icon' /> Cancel Order</li>
<li onClick={() => transation()}><img src='/images/view.gif' alt='side-icon' /> Transation</li>
</ul>
<li onClick={() => couponMenu()}><img src='/images/voucher.gif' alt='side-icon' /> Coupon <i className='fa fa-caret-right side-rightIcon'></i></li>
<ul className='side-dropdown' id='couponMenu'>
<li onClick={() => addcou()}><img src='/images/add.gif' alt='side-icon' /> Create Coupon</li>
</ul>
<li onClick={() => taxMenu()}><img src='/images/tax.gif' alt='side-icon' /> Tax <i className='fa fa-caret-right side-rightIcon'></i></li>
<ul className='side-dropdown' id='taxMenu'>
<li onClick={() => addtx()}><img src='/images/add.gif' alt='side-icon' /> Create Tax</li>
</ul>
<li onClick={() => invoicePage()}><img src='/images/bill.gif' alt='side-icon' /> Invoice</li>
<li onClick={() => tabelPage()}><img src='/images/table.gif' alt='side-icon' /> Tabels</li>
<li onClick={() => customerPage()}><img src='/images/best-customer-experience.gif' alt='side-icon' /> Customer</li>
<li onClick={() => reportsPage()}><img src='/images/line-bars.gif' alt='side-icon' /> Reports</li>
<li onClick={() => settingMenu()}><img src='/images/settings.gif' alt='side-icon' /> Settings <i className='fa fa-caret-right side-rightIcon'></i></li>
<ul className='side-dropdown' id='settingMenu'>
<li onClick={() => contactPage()}><img src='/images/work-in-progress.gif' alt='side-icon' /> Contact Setting</li>
<li onClick={() => gstPage()}><img src='/images/work-in-progress.gif' alt='side-icon' /> GST Setting</li>
<li onClick={() => socialPage()}><img src='/images/work-in-progress.gif' alt='side-icon' /> Social Setting</li>
</ul>
</ul>
</div>

</div>
</>
)
}

export default LeftSidebar;
