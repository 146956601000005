import React, {useEffect, useState } from 'react';
import $ from 'jquery';
import APModal from '../modals/APModal';
import { GlobalData } from '../context/AppContext';
import EAPModal from '../modals/EAPModal';
import RPModal from '../modals/RPModal';

const AddProduct = () => {

const  { productData } = GlobalData();
const [editData, setEditData] = useState([]);
const [currpage, setPage] = useState(1);
const [fcategory, setCategory] = useState([]);
const [sortpage, setSort] = useState(50);
const recordPage = sortpage;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);

const insertProduct = () => {
$('#addProductModal').fadeIn().show();   
}

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const SearchFilter = (e) => {
setCategory(productData.filter(a => a.name.toLowerCase().includes(e.target.value)));
}

const FilterSort = (e) => {
setSort(e.target.value);
}

const editProduct = (id) => {
setEditData(productData.filter((item) => item.id === id));
$('#editProductModal').fadeIn().show();
}

const deleteProduct = (id) => {
$('#removeProductModal').fadeIn().show();
$('#removeId').val(id);
}

useEffect(() => {
if(productData){
setCategory(productData);
}
},[productData]);

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Add Product</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / Add Product</span>
</div>
</div>

<div className='content-wrap'>
<div className='content-top-button'>
<button onClick={() => insertProduct()}><i className='fa fa-plus'></i> Add Product</button>
</div>

<div className='contentBody'>
<div className='body-wrap-content'>

<div className='card-bodysingle'>

<div className='search-div-wrap'>
<div className='sort-div'>
<label>Sort By Page:</label>
<select onChange={FilterSort}>
<option>50</option>
<option>100</option>
<option>250</option>
<option>500</option>
</select>
</div>

<div className='input-search-div'>
<input type='text' placeholder='Search Products...' onChange={SearchFilter} />
</div>
</div>

<div className='tabel-Responsive'>
<table className='tabelLarge'>
<thead>
<tr>
<th>Id</th>
<th>Name</th>
<th>Category</th>
<th>Type</th>
<th>Price</th>
<th>Coupon</th>
<th>Image</th>
<th>Stock</th>
<th>Action</th>
</tr>
</thead>
{
records.length > 0 ? records.map((data, index) => {
var stockItem = 'In-Stock';
var couponVal = 'no-coupon';
if(data.stock === 'available'){
stockItem = <span className='instock'>In-Stock</span>;
}
else{
stockItem = <span className='outstock'>Out-Of-Stock</span>;
}

if(data.coupon !== null && data.coupon !== 'null'){
couponVal = data.coupon;
}
else{
couponVal = 'no-coupon';
}

return (
<tbody key={index}>
<tr>
<td>{data.id}</td>
<td>{data.name}</td>
<td>{data.category}</td>
<td>{data.types}</td>
<td>{data.price.toFixed(2)}</td>
<td>{couponVal}</td>
<td><img src={data.path} className='tablesmimg' alt='imagesCat' /></td>
<td>{stockItem}</td>
<td>
<button className='smBtn' onClick={() => editProduct(data.id)}><i className='fa fa-cog'></i></button>
<button className='smBtn' onClick={() => deleteProduct(data.id)}><i className='fa fa-trash'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='9'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>


<div className='pagination-wrap'>
<div className='pagination-div'><span className='totalData'>Total Records: {fcategory.length}</span></div>

<div className='pagination-div'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>
</div>

</div>
</div>


</div>
</div>

</div>
</div>

<APModal />
<EAPModal data={editData} />
<RPModal />
</>
)
}

export default AddProduct;
