import React from 'react';
import SellReports from './SellReports';
import CancelReports from './CancelReports';

const Reports = () => {
return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Reports</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / All Reports</span>
</div>
</div>

<div className='content-wrap'>
<SellReports />
<CancelReports />
</div>

</div>
</div>
</>
)
}

export default Reports;
