import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalData } from '../context/AppContext';

const AllOrders = () => {

const Navigate = useNavigate();
const { allOrders } = GlobalData();
const [currpage, setPage] = useState(1);
const [fcategory, setCategory] = useState([]);
const [sortpage, setSort] = useState(50);
const recordPage = sortpage;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const SearchFilter = (e) => {
setCategory(allOrders.filter(a => a.orderStatus.toLowerCase().includes(e.target.value)));
}

const FilterSort = (e) => {
setSort(e.target.value);
}

const viewOrder = (id) => {
Navigate('/view-orders/'+id);
}

useEffect(() => {
if(allOrders.length > 0){
setCategory(allOrders);
}
},[allOrders]);
return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Orders List</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / All Orders</span>
</div>
</div>

<div className='content-wrap'>
<div className='card-bodysingle'>

<div className='search-div-wrap'>
<div className='sort-div'>
<label>Sort By Page:</label>
<select onChange={FilterSort}>
<option>50</option>
<option>100</option>
<option>250</option>
<option>500</option>
</select>
</div>

<div className='input-search-div'>
<input type='text' placeholder='Search Order Status...' onChange={SearchFilter} />
</div>
</div>

<div className='tabel-Responsive'>
<table className='tabelLarge'>
<thead>
<tr>
<th>Order Id</th>
<th>User</th>
<th>Contact</th>
<th>Table No</th>
<th>Order Status</th>
<th>Payment Status</th>
<th>Order Date</th>
<th>Action</th>
</tr>
</thead>
{
records.length > 0 ? records.map((data, index) => {
return (
<tbody key={index}>
<tr>
<td>{data.orderId}</td>
<td>{data.user}</td>
<td>{data.contact}</td>
<td>{data.tabelNo}</td>
<td>{data.orderStatus}</td>
<td>{data.payStatus}</td>
<td>{data.dateTime}</td>
<td>
<button className='smBtn' onClick={() => viewOrder(data.orderId)}><i className='fa fa-eye'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='8'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>

<div className='pagination-wrap'>
<div className='pagination-div'><span className='totalData'>Total Records: {fcategory.length}</span></div>

<div className='pagination-div'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>
</div>

</div>

</div>


</div>
</div>
</>
)
}

export default AllOrders;
