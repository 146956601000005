import React, { useEffect, useState } from 'react';
import { GlobalData } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const OrderPlaced = () => {

const { allOrders } = GlobalData();
const [fcategory, setCategory] = useState([]);
const [currpage, setPage] = useState(1);
const recordPage = 10;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);
const Navigate = useNavigate();

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const viewOrder = (id) => {
Navigate('/view-orders/'+id);
}

useEffect(() => {
if(allOrders.length > 0){
setCategory(allOrders.filter((e) => e.orderStatus === 'order placed'));
}
},[allOrders]);
return (
<>
<div className='orderData-div'>
<div className='wrapTitle-orderData'>
<img src='../images/new.gif' alt='new' />
<span>Placed Orders</span>
</div>
<div className='wrap-orderData'>
<span className='pendingSpan'>No Of Total Placed Orders : {records.length}</span>
<div className='tabel-Responsive'>
<table className='tabelLarge'>
<thead>
<tr>
<th>Order Id</th>
<th>User</th>
<th>Contact</th>
<th>Table No</th>
<th>Order Status</th>
<th>Payment Status</th>
<th>Order Date</th>
<th>Action</th>
</tr>
</thead>
{
records.length > 0 ? records.map((data, index) => {
return (
<tbody key={index}>
<tr>
<td>{data.orderId}</td>
<td>{data.user}</td>
<td>{data.contact}</td>
<td>{data.tabelNo}</td>
<td>{data.orderStatus}</td>
<td>{data.payStatus}</td>
<td>{data.dateTime}</td>
<td>
<button className='smBtn' onClick={() => viewOrder(data.orderId)}><i className='fa fa-eye'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='8'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>

<div className='pagination-wrap'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>

</div>
</div>
</>
)
}

export default OrderPlaced;
