import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const RemoveOrder = () => {

const closeRemove = () => {
$('#removeOrderItem').hide();
}

const deleteItemOrder = async() => {
var id = $('#removeId').val();
try {
const sendData = await axios.post('/node-server/remove-order', {id: id});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Item removed successfull'); 
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData); 
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend remove-order-item-api error: ' + error);
}
}

return (
<>
<div className='modals' id='removeOrderItem'>
<div className='modals-content-remove'>
<div className='modals-haeder'><i className='fa fa-trash'></i> Remove Item <i className='fa fa-close rightModalIcon' onClick={() => closeRemove()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<center><img src='../images/trash-bin.gif' alt='trash' id='editCatImg' />
<input type='hidden' id='removeId' />
<span>Are you sure? You want to remove this item?</span></center>
<div className='yesNoDiv'>
<button onClick={() => deleteItemOrder()}>Yes</button>
<button onClick={() => closeRemove()}>No</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default RemoveOrder;
