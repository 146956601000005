import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';

const ETModal = ({datas}) => {
const [getTaxEdit, setGetTaxEdit] = useState([]);

const CloseModal = () => {
$('#editTaxModal').hide();
setGetTaxEdit([]);
}

const updateTax = async() => {
var id = $('#editIdTax').val();
var name = $('#editTaxName').val();
var rate = $('#editTaxRate').val();
var status = $('#editTaxStatus').val();
//alert(id +' -- '+ name +' -- '+ rate +' -- '+ status);
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tax name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);  
return false;
}
if(rate === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tax rate required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);  
return false;
}
if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tax status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);  
return false;
}
else{
try {
const sendData = await axios.post('/node-server/update-tax', {id: id, name: name, rate: rate, status: status});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Tax updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend update-tax-api error: ' + error); 
}
}
}

useEffect(() => {
setGetTaxEdit(datas);
},[datas]);

return (
<>
<div className='modals' id='editTaxModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-edit'></i> Edit Tax <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
{
getTaxEdit.length > 0 &&
<div>
<div className='input-div'>
<label>Tax Name</label>
<input type='hidden' id='editIdTax' defaultValue={getTaxEdit[0].id} />
<input type='text' id='editTaxName' placeholder='Enter tax name...' defaultValue={getTaxEdit[0].name} />
</div>
<div className='input-div'>
<label>Tax Rate</label>
<input type='number' id='editTaxRate' placeholder='Enter tax rate...' defaultValue={getTaxEdit[0].rate} />
</div>
<div className='input-div'>
<label>Tax Status</label>
<select id='editTaxStatus'>
<option value={getTaxEdit[0].status}>{getTaxEdit[0].status}</option>
<option value='null'>--select tax status--</option>
<option value='enable'>Enable</option>
<option value='disable'>Disable</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => updateTax()}><i className='fa fa-upload'></i> Update Tax</button>
</div>
</div>
}
</div>
</div>

</div>
</div>
</>
)
}

export default ETModal;
