import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const ATModal = () => {

const CloseModal = () => {
$('#addTaxModal').hide();
}

const add_tax = async() => {
var name = $('#taxName').val();   
var rate = $('#taxRate').val();   
var status = $('#taxStatus').val();  
//alert(name +' -- '+ rate +' -- '+ status); 
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tax name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
if(rate === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tax rate required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tax status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/add-tax', {name: name, rate: rate, status: status});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Tax added successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
window.location.reload(); 
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
},1000);
}
} catch (error) {
console.log('backend tax-add-api error: ' + error);
}
}
}

const handlePrc = (e) => {
if(e.target.value < 0){
$('#taxRate').val('0');
}
}

return (
<>
<div className='modals' id='addTaxModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-plus'></i> Add Tax <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='input-div'>
<label>Tax Name</label>
<input type='text' id='taxName' placeholder='Enter tax name...' />
</div>
<div className='input-div'>
<label>Tax Rate</label>
<input type='number' id='taxRate' placeholder='Enter tax rate...' onChange={handlePrc} />
</div>
<div className='input-div'>
<label>Tax Status</label>
<select id='taxStatus'>
<option value='null'>--select tax status--</option>
<option value='enable'>Enable</option>
<option value='disable'>Disable</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => add_tax()}><i className='fa fa-plus'></i> Add Tax</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default ATModal;
