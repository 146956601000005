import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GlobalData } from '../context/AppContext';

const SellReports = () => {

const { orderPrd, taxData } = GlobalData();

const data = [];

const reduceData = orderPrd.length > 0 && orderPrd.reduce((curr, elem) => {
const { itemTypes, itemPrice, itemQuantity, itemStock, payStatus } = elem;
const item = curr.find((it) => it.itemTypes === itemTypes);

let newPrc = 0;
if (payStatus === 'completed'){
if (itemQuantity === 'half'){
newPrc = (parseFloat(itemPrice) * parseInt(itemStock)) / 2; 
}
else{
newPrc = parseFloat(itemPrice) * parseInt(itemStock); 
}

item ? item.newPrc += newPrc : curr.push({itemTypes, newPrc});
}
return curr;
},[]);

if (reduceData.length > 0){
reduceData.forEach(element => {

const TaxReduce = taxData.length > 0 && taxData.reduce((acc, el) => {
const { rate } = el;
acc += (parseFloat(element.newPrc) * parseFloat(rate)) / 100;
return acc;
},0);

data.push({name: element.itemTypes.toUpperCase(), SELL: (parseFloat(element.newPrc) + parseFloat(TaxReduce !== '' ? TaxReduce : 0))});   
});
}

return (
<>
<div className='chartsDivs'>
<span className='reportTitle'>Total Sell Reports</span>   
<hr className='hr1' /> 
<ResponsiveContainer width="100%" height={300}>
<BarChart
width="100%"
height={300}
data={data}
margin={{
top: 5,
right: 30,
left: 20,
bottom: 5,
}}
>
<CartesianGrid strokeDasharray="3 3" />
<XAxis dataKey="name" />
<YAxis />
<Tooltip />
<Legend />
<Bar dataKey="SELL" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
{/*<Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />*/}
</BarChart>
</ResponsiveContainer>
</div>
</>
)
}

export default SellReports;
