import { createContext, useContext, useEffect, useReducer } from "react";
import axios from 'axios';
import Reducer from "../reducer/Reducer";

const Context = createContext();

const API = '/node-server/list-category';
const API2 = '/node-server/list-products';
const API3 = '/node-server/list-coupon';
const API4 = '/node-server/list-tax';
const API5 = '/node-server/allList-orders';
const API6 = '/node-server/allitems-orders';
const API7 = '/node-server/allList-remove';
const API8 = '/node-server/allitems-remove';
const API9 = '/node-server/list-tabel';
const API10 = '/node-server/all-customers';
const API11 = '/node-server/admin-details';
const API12 = '/node-server/store-info';
const API13 = '/node-server/store-gst-info';
const API14 = '/node-server/store-social-info';

const initialState = {
isLoading: false,
isError: false,
isUserLoading: false,
isUserError: false,
categoryData: [],
productData: [],
couponData: [],
taxData: [],
allOrders: [],
orderPrd: [],
allRemove: [],
itemRemove: [],
allTabels: [],
customers: [],
adminData: [],
storeInfo: [],
storeGst: [],
storeSocial: []
}

const Provider = ({children}) => {
const [state, dispatch] = useReducer(Reducer, initialState);

const GetCategory = async(URL) => {
dispatch({type: "CATEGORY_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "CATEGORY_SET", payload:resData});
} catch (error) {
dispatch({type: "CATEGORY_ERROR"}); 
}  
}

const GetProduct = async(URL) => {
dispatch({type: "PRODUCT_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "PRODUCT_SET", payload:resData});
} catch (error) {
dispatch({type: "PRODUCT_ERROR"}); 
}  
}

const GetCoupon = async(URL) => {
dispatch({type: "COUPON_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "COUPON_SET", payload:resData});
} catch (error) {
dispatch({type: "COUPON_ERROR"}); 
}  
}

const GetTaxes = async(URL) => {
dispatch({type: "TAX_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "TAX_SET", payload:resData});
} catch (error) {
dispatch({type: "TAX_ERROR"}); 
}  
}

const GetOrders = async(URL) => {
dispatch({type: "ORDERS_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "ORDERS_SET", payload:resData});
} catch (error) {
dispatch({type: "ORDERS_ERROR"}); 
}  
}

const GetPrdOrder = async(URL) => {
dispatch({type: "ORDERS_PRD_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "ORDERS_PRD_SET", payload:resData});
} catch (error) {
dispatch({type: "ORDERS_PRD_ERROR"}); 
}  
}

const GetPrdRemove = async(URL) => {
dispatch({type: "REMOVE_PRD_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "REMOVE_PRD_SET", payload:resData});
} catch (error) {
dispatch({type: "REMOVE_PRD_ERROR"}); 
}  
}

const GetItemRemove = async(URL) => {
dispatch({type: "REMOVE_ITEM_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "REMOVE_ITEM_SET", payload:resData});
} catch (error) {
dispatch({type: "REMOVE_ITEM_ERROR"}); 
}  
}

const GetTabels = async(URL) => {
dispatch({type: "TABELS_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "TABELS_SET", payload:resData});
} catch (error) {
dispatch({type: "TABELS_ERROR"}); 
}  
}

const GetCustomer = async(URL) => {
dispatch({type: "CUSTOMER_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "CUSTOMER_SET", payload:resData});
} catch (error) {
dispatch({type: "CUSTOMER_ERROR"}); 
}  
}

const GetAdminData = async(URL) => {
dispatch({type: "ADMIN_DATA_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "ADMIN_DATA_SET", payload:resData});
} catch (error) {
dispatch({type: "ADMIN_DATA_ERROR"}); 
}  
}

const GetStoreInfo = async(URL) => {
dispatch({type: "STORE_DATA_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "STORE_DATA_SET", payload:resData});
} catch (error) {
dispatch({type: "STORE_DATA_ERROR"}); 
}  
}

const GetStoreGst = async(URL) => {
dispatch({type: "STORE_GST_DATA_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "STORE_GST_DATA_SET", payload:resData});
} catch (error) {
dispatch({type: "STORE_GST_DATA_ERROR"}); 
}  
}

const GetStoreSocial = async(URL) => {
dispatch({type: "STORE_SOCIAL_DATA_LOADING"});
try {
const sendData = await axios.get(URL); 
const resData = await sendData.data;
dispatch({type: "STORE_SOCIAL_DATA_SET", payload:resData});
} catch (error) {
dispatch({type: "STORE_SOCIAL_DATA_ERROR"}); 
}  
}

useEffect(() => {
GetCategory(API);
GetProduct(API2);
GetCoupon(API3);
GetTaxes(API4);
GetOrders(API5);
GetPrdOrder(API6);
GetPrdRemove(API7);
GetItemRemove(API8);
GetTabels(API9);
GetCustomer(API10);
GetAdminData(API11);
GetStoreInfo(API12);
GetStoreGst(API13);
GetStoreSocial(API14);
},[]);

useEffect(() => {
setInterval(function(){
GetOrders(API5);
GetPrdOrder(API6);
GetCustomer(API10);
},5000);
},[]);

return (
<Context.Provider value={{...state, GetPrdOrder}}>{children}</Context.Provider>
);
}

const GlobalData = () => {
return useContext(Context);
}

export {Context, Provider, GlobalData};