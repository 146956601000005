import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';

const RemoveAllTabel = () => {

const { allTabels } = GlobalData();

const closeRemove = () => {
$('#removeAlltabel').hide();
}

const deleteAllTabels = () => {
allTabels.length > 0 && allTabels.forEach(async element => {
// alert(element.id +' -- '+ element.tabelNo);
var id = element.id;
var tabelNo = element.tabelNo;
try {
const sendData = await axios.post('/node-server/inactiveTabel-all', {id: id, tabelNo: tabelNo});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Inactive all tabel successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend inactive-all-tabels-api error: ' + error); 
}
});
}

return (
<>
<div className='modals' id='removeAlltabel'>
<div className='modals-content-remove'>
<div className='modals-haeder'><i className='fa fa-ban'></i> Inactive Tabel <i className='fa fa-close rightModalIcon' onClick={() => closeRemove()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<center><img src='../images/trash-bin.gif' alt='trash' id='editCatImg' />
<span>Are you sure? You want to inactive all tabels?</span></center>
<div className='yesNoDiv'>
<button onClick={() => deleteAllTabels()}>Yes</button>
<button onClick={() => closeRemove()}>No</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default RemoveAllTabel;
