import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalData } from '../context/AppContext';
import AddCustomer from '../modals/AddCustomer';
import EditCustomer from '../modals/EditCustomer';
import RemoveUser from '../modals/RemoveUser';

const Customers = () => {

const { customers } = GlobalData();
const [filterData, setFilterData] = useState([]);
const [currpage, setPage] = useState(1);
const [fcategory, setCategory] = useState([]);
const [sortpage, setSort] = useState(50);
const recordPage = sortpage;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const SearchFilter = (e) => {
setCategory(customers.filter(a => a.name.toLowerCase().includes(e.target.value)));
}

const FilterSort = (e) => {
setSort(e.target.value);
}

const addCustomer = () => {
$('#addUserModal').fadeIn().show();
}

const editCustomer = (id) => {
setFilterData(customers.filter((e) => e.id === id));
$('#editUserModal').fadeIn().show();
}

const removeCustomer = (id) => {
$('#removeUserModal').fadeIn().show();
$('#removeId').val(id);
}

useEffect(() => {
if(customers !== undefined){
setCategory(customers);
}
},[customers]);

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Customer List</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / All Customer</span>
</div>
</div>

<div className='content-wrap'>
<div className='content-top-button'>
<button onClick={() => addCustomer()}><i className='fa fa-plus'></i> Add Customer</button>
</div>

<div className='contentBody'>
<div className='body-wrap-content'>

<div className='card-bodysingle'>

<div className='search-div-wrap'>
<div className='sort-div'>
<label>Sort By Page:</label>
<select onChange={FilterSort}>
<option>50</option>
<option>100</option>
<option>250</option>
<option>500</option>
</select>
</div>

<div className='input-search-div'>
<input type='text' placeholder='Search customer...' onChange={SearchFilter} />
</div>
</div>

<div className='tabel-Responsive'>
<table className='tabelLarge'>
<thead>
<tr>
<th>Id</th>
<th>Name</th>
<th>Contact</th>
<th>Email</th>
<th>City</th>
<th>State</th>
<th>Status</th>
<th>Date</th>
<th>Action</th>
</tr>
</thead>
{
records.length > 0 ? records.map((data, index) => {
var newSta;
if (data.status === 'active'){
newSta = <span className='instock'>Active</span>
}
else{
newSta = <span className='outstock'>In-Active</span>
}
return (
<tbody key={index}>
<tr>
<td>{data.id}</td>
<td>{data.name}</td>
<td>{data.contact}</td>
<td>{data.email ? data.email : '--'}</td>
<td>{data.citym ? data.citym : '--'}</td>
<td>{data.state ? data.state : '--'}</td>
<td>{newSta}</td>
<td>{data.dateTime}</td>
<td>
<button onClick={() => editCustomer(data.id)} className='smBtn'><i className='fa fa-edit'></i></button>
<button onClick={() => removeCustomer(data.id)} className='smBtn'><i className='fa fa-trash'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='9'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>


<div className='pagination-wrap'>
<div className='pagination-div'><span className='totalData'>Total Records: {fcategory.length}</span></div>

<div className='pagination-div'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>
</div>

</div>
</div>


</div>
</div>

</div>
</div>

<AddCustomer />
<EditCustomer data={filterData} />
<RemoveUser />
</>
)
}

export default Customers;
