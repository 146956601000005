import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalData } from '../context/AppContext';
import AddTabel from '../modals/AddTabel';
import TabelEdit from '../modals/TabelEdit';
import TabelStatus from '../modals/TabelStatus';
import RemoveTabel from '../modals/RemoveTabel';
import RemoveAllTabel from '../modals/RemoveAllTabel';

const Tables = () => {

const { allTabels } = GlobalData();
const [filterData, setFilterData] = useState([]);
const [filterStat, setFilterStat] = useState([]);
const [currpage, setPage] = useState(1);
const [fcategory, setCategory] = useState([]);
const [sortpage, setSort] = useState(50);
const recordPage = sortpage;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const SearchFilter = (e) => {
setCategory(allTabels.filter(a => a.name.toLowerCase().includes(e.target.value)));
}

const FilterSort = (e) => {
setSort(e.target.value);
}

const addTabel = () => {
$('#addTabelModal').fadeIn().show();
}

const editTabel = (id) => {
setFilterData(allTabels.filter((e) => e.id === id));
$('#editTabelModal').fadeIn().show();
}

const editStatusTabel = (id) => {
setFilterStat(allTabels.filter((e) => e.id === id)); 
$('#editStatusTabelModal').fadeIn().show();  
}

const removeStatusTabel = (id) => {
$('#removeTabelModal').fadeIn().show(); 
$('#removeId').val(id);
}

const activeTabel = () => {
$('#removeAlltabel').fadeIn().show();
}

useEffect(() => {
if(allTabels.length > 0){
setCategory(allTabels);
}
},[allTabels]);

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Tabels</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / All Tabels</span>
</div>
</div>

<div className='content-wrap'>
<div className='content-top-button'>
<button onClick={() => addTabel()}><i className='fa fa-plus'></i> Add Tabel</button>
<button onClick={() => activeTabel()}><i className='fa fa-ban'></i> InActive All Tabel</button>
</div>

<div className='contentBody'>
<div className='body-wrap-content'>

<div className='card-bodysingle'>

<div className='search-div-wrap'>
<div className='sort-div'>
<label>Sort By Page:</label>
<select onChange={FilterSort}>
<option>50</option>
<option>100</option>
<option>250</option>
<option>500</option>
</select>
</div>

<div className='input-search-div'>
<input type='text' placeholder='Search Tabel...' onChange={SearchFilter} />
</div>
</div>

<div className='tabel-Responsive'>
<table className='tabelSmall'>
<thead>
<tr>
<th>Id</th>
<th>Name</th>
<th>Tabel No</th>
<th>Status</th>
<th>Action</th>
</tr>
</thead>
{
records.length > 0 ? records.map((data, index) => {
var stat;
if(data.status === 'active'){
stat = <span className='instock'>Active</span>;
}
else{
stat = <span className='outstock'>In-Active</span>;  
}
return (
<tbody key={index}>
<tr>
<td>{data.id}</td>
<td>{data.name}</td>
<td>{data.tabelNo}</td>
<td>{stat}</td>
<td>
<button onClick={() => editTabel(data.id)} className='smBtn'><i className='fa fa-edit'></i></button>
<button onClick={() => editStatusTabel(data.id)} className='smBtn'><i className='fa fa-cog'></i></button>
<button onClick={() => removeStatusTabel(data.id)} className='smBtn'><i className='fa fa-trash'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='5'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>


<div className='pagination-wrap'>
<div className='pagination-div'><span className='totalData'>Total Records: {fcategory.length}</span></div>

<div className='pagination-div'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>
</div>

</div>
</div>


</div>
</div>

</div>
</div>

<AddTabel />
<TabelEdit data={filterData} />
<TabelStatus data={filterStat} />
<RemoveTabel />
<RemoveAllTabel />
</>
)
}

export default Tables;
