import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';

const SettingGst = () => {

const { storeGst } = GlobalData();

const updateGst = async() => {
var id = storeGst.length > 0 && storeGst[0].id;
var gstNo = $('#storeGST').val();
var regNo = $('#storeRegd').val();
try {
const sendData = await axios.post('/node-server/updateStore-gst', {id: id, gstNo: gstNo, regNo: regNo});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Gst updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend update-setting-gst-error: ' + error);
}
}

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Edit GST Setting</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / GST Setting</span>
</div>
</div>

<div className='content-wrap'>
{
storeGst.length > 0 && storeGst.map((data, index) => {
return (
<div className='card-bodysingle' key={index}>
<div className='input-div'>
<label>Store GST Number :</label>
<input type='text' id='storeGST' placeholder='Enter store gst number...' defaultValue={data.gst} />
</div>
<div className='input-div'>
<label>Store Registration Number :</label>
<input type='text' id='storeRegd' placeholder='Enter store Registration number...' defaultValue={data.regi} />
</div>
<div className='input-div-submit'>
<button onClick={() => updateGst()}><i className='fa fa-edit'></i> Update GST</button>
</div>
</div>
)
})
}
</div>

</div>
</div>
</>
)
}

export default SettingGst;
