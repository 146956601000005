import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const Login = () => {

const loginAdmin = async() => {
var user = $('#adminUser').val();
var pass = $('#adminPass').val();
if (user === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Username required...');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if (pass === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Password required...');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/control-login', {user: user, pass: pass});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Account login successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.replace('/');
},1000);
return false;   
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend login-api error: ' + error);
}
}
}

return (
<>
<div className='enteryPage'>
<div className='enteryContent'>
<div className='enteryLogo'>
<center>
<img src='../images/logoMain.png' alt='booking' />
<span>Control Panel</span>
<hr className='line1' />
</center>
</div>
<div>
<div className='input-div'>
<label>Username :</label>
<input type='text' id='adminUser' placeholder='Enter username...' />
</div>
<div className='input-div'>
<label>Password :</label>
<input type='text' id='adminPass' placeholder='Enter password...' />
</div>

<div className='inputDivSubmit'>
<button onClick={() => loginAdmin()}>Log In</button>
</div>
</div>
</div>
</div>
</>
)
}

export default Login;
