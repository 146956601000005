import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
const Navigate = useNavigate();

useEffect(() => {
const LogAuth = async() => {
try {
const sendData = await axios.get('/node-server/adminLogin-auth');
const resData = await sendData.data;
console.log(resData);
if(resData !== 'success'){
Navigate('/login'); 
}
} catch (error) {
console.log('backend admin-login-auth-api error: ' + error);
}
}
LogAuth();
},[Navigate]);
return null;
}

export default Auth;
