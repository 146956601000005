import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const ECModal = ({datas}) => {
const [editGet, setEditGet] = useState([]);
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());

const CloseModal = () => {
$('#editCouponModal').hide();
setEditGet([]);
}

const editCoupon = async() => {
var id = $('#editCouponId').val();
var name = $('#editCouponName').val();
var rate = $('#editCouponRate').val();
var type = $('#editCouponType').val();
var start_Date = startDate;
var end_Date = endDate;
var status = $('#editCouponStatus').val();
//alert(name +' -- '+ rate +' -- '+ type +' -- '+ start_Date +' -- '+ end_Date +' -- '+ status);
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(rate === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon rate required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(type === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon type required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(start_Date === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon start date required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(end_Date === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon end date required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Coupon status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/update-coupon', {
id: id,
name: name,
rate: rate,
type: type,
start_Date: start_Date,
end_Date: end_Date,
status: status
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Coupon updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend update-coupon-api error: ' + error);
}
}
}

useEffect(() => {
setEditGet(datas);
},[datas]);

return (
<>
<div className='modals' id='editCouponModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-edit'></i> Edit Coupon <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
{
editGet.length > 0 &&
<div>
<div className='input-div'>
<label>Coupon Name</label>
<input type='hidden' id='editCouponId' defaultValue={editGet[0].id} />
<input type='text' id='editCouponName' placeholder='Enter coupon name...' defaultValue={editGet[0].name} />
</div>
<div className='input-div'>
<label>Coupon Rate</label>
<input type='number' id='editCouponRate' placeholder='Enter coupon rate...' defaultValue={editGet[0].rate}/>
</div>
<div className='input-div'>
<label>Coupon Type</label>
<select id='editCouponType'>
<option value={editGet[0].type}>{editGet[0].type}</option>
<option value='null'>--select copon type--</option>
<option value='fixed'>Fixed</option>
<option value='percent'>percent</option>
</select>
</div>
<div className='input-div'>
<label>Start Date</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='input-div'>
<label>End Date</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
<div className='input-div'>
<label>Status</label>
<select id='editCouponStatus'>
<option value={editGet[0].status}>{editGet[0].status}</option>  
<option value='null'>--select status--</option>
<option value='enable'>Enable</option>
<option value='disable'>Disable</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => editCoupon()}><i className='fa fa-upload'></i> Update Coupon</button>
</div>
</div>
}
</div>
</div>

</div>
</div>
</>
)
}


export default ECModal;
