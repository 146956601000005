import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { useReactToPrint } from 'react-to-print';
import { GlobalData } from '../context/AppContext';

const InvoiceView = ({data}) => {
const { taxData, couponData, storeGst } = GlobalData();
const [invoiceData, setInvoiceData] = useState([]);

const componentRef = useRef(null);
const handlePrint = useReactToPrint({
content: () => componentRef.current,
});

const CloseModal = () => {
$('#invoiceModal').hide();
setInvoiceData([]);
}

const reduceData = invoiceData.length > 0 && invoiceData.reduce((curr, elem) => {
const { orderId, dateTime, currPrc, itemStock, orderStatus, payStatus, paymentBy, payOpt, tabelNo, user, contact, couponName} = elem;
const item = curr.find((it) => it.orderId === orderId);
let newPrc = parseFloat(currPrc) * parseInt(itemStock);
item ? item.newPrc += newPrc : curr.push({orderId,dateTime,newPrc,itemStock,orderStatus,payStatus,paymentBy,payOpt,tabelNo,user,contact,couponName});
return curr;
},[]);

useEffect(() => {
if(data.length > 0){
setInvoiceData(data);    
}
},[data]);
return (
<>
<div className='modals' id='invoiceModal'>
<div className='modals-content-md'>
<div className='modals-haeder'><i className='fa fa-print'></i> Invoice <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
{
reduceData && reduceData.map((data, index) => {

const taxdata = taxData.length > 0 && taxData.reduce((acc, el) => {
const { rate } = el;
acc += (parseFloat(data.newPrc) * parseFloat(rate)) / 100;
return acc;
},0);

const couponVal = couponData.length > 0 && couponData.filter((e) => e.name === data.couponName).reduce((e, f) => {
const { type, rate } = f;
if (type === 'percent'){
e = (parseFloat(data.newPrc) * parseFloat(rate)) / 100; 
}
else{
e = rate;
}
return e;
},0);

return (
<div ref={componentRef} className='invoice-body' key={index}>
<div className='invoice-content'>
<div className='Top-haederInv'>
<div className='topInv-col'>
<span>Date : {new Date().toLocaleDateString()}</span>
{
storeGst.length > 0 && storeGst.map((d, m) => {
return (
<span className='regi' key={m}>
<span>GST No : {d.gst}</span>
{
d.regi !== '' &&
<span>Registraion No : {d.regi}</span>
}
</span>
)
})
}
</div>
<div className='topInv-col'><span><i className='fa fa-phone'></i> +91 86036 33736</span></div>
</div>
<div className='invoice-head'>
<img src='../images/logoMain.png' alt='logoMain' />
<span className='invoiceTitle'>Fun & Food Restrauant</span>
<span className='invoiceAddress'><i className='fa fa-map-marker'></i> Vijay Nagar, Kankarbagh, Patna, Bihar, Pin - 800026</span>
</div>
<hr className='invHr' />
<div className='invoice-headSec'>
<span className='invoiceSm-txt'><i className='fa fa-hand-o-right'></i> Order Id : {data.orderId}</span>
<span className='invoiceSm-txt'><i className='fa fa-hand-o-right'></i> Order Date : {data.dateTime}</span>
<span className='invoiceSm-txt'><i className='fa fa-hand-o-right'></i> Tabel : {data.tabelNo}</span>
</div>
<hr className='invHr' />

<div className='invoice-itemsDetails'>
<div className='item-TitleInv'><span>Item Details</span></div>

<table className='itemsTable-Inv'>
<thead>
<tr>
<th>Item Name</th>
<th>Qunatity</th>
<th>No Of Item</th>
<th>Item Price</th>
<th>Price</th>
</tr>
</thead>
{
invoiceData.length > 0 && invoiceData.filter((e) => e.orderId === data.orderId).map((data2, index2) => {
let newPrc = 0;
let currPrc = 0;
if (data2.itemQuantity === 'half') {
newPrc = parseFloat(data2.itemPrice) / 2; 
currPrc = (parseFloat(data2.itemPrice) * parseInt(data2.itemStock)) / 2;
}
else{
newPrc = parseFloat(data2.itemPrice);
currPrc = parseFloat(data2.itemPrice) * parseInt(data2.itemStock) 
}
return (
<tbody key={index2}>
<tr>
<td>{data2.itemName}</td>
<td>{data2.itemQuantity}</td>
<td>{data2.itemStock}</td>
<td>₹{newPrc.toFixed(2)}</td>
<td>₹{currPrc.toFixed(2)}</td>
</tr>
</tbody>
)
})
}
</table>

<div className='totalInv-prcWrap'>
<div className='totalInv-prc'>
<div className='colInv'><span>Price</span></div>
<div className='colInv'><span>₹{data.newPrc.toFixed(2)}</span></div>
</div>

{
couponData.length > 0 && couponData.filter((e) => e.name === data.couponName).map((cou, ind) => {
let couDis = 0;
if(cou.type === 'percent'){
couDis = (parseFloat(data.newPrc) * parseInt(cou.rate)) / 100;
}
else{
couDis = cou.rate;
}
return (  
<div className='totalInv-prc' key={ind}>
<div className='colInv'><span>Coupon Discount</span></div>
<div className='colInv'><span>₹ -{couDis.toFixed(2)}</span></div>
</div>
)
})
}

{
taxData.length > 0 && taxData.map((data2, index2) => {
let a = 0;
a = (parseFloat(data.newPrc) * parseFloat(data2.rate)) / 100;
return (
<div className='totalInv-prc' key={index2}>
<div className='colInv'><span>{data2.name +' ('+ data2.rate +'%)'}</span></div>
<div className='colInv'><span>₹{a.toFixed(2)}</span></div>
</div>
)
})
}
</div>

<div className='totalInv-prc'>
<div className='colInv'><span>Total Price</span></div>
<div className='colInv'><span>₹{((parseFloat(data.newPrc) + parseFloat(taxdata)) - parseFloat(couponVal)).toFixed(2)}</span></div>
</div> 

<div className='payStatusCurr'>
{
data.payStatus === 'waiting' ?
<img src='../images/food-cover.png' alt='cancelled' />
: data.payStatus === 'cancelled' ?
<img src='../images/cancelled.png' alt='cancelled' />
:
<img src='../images/paid.png' alt='cancelled' />
}   
</div>
</div>

<div className='OrderDetails-inv'>
<div className='details-headInv'>
<span>Order Information</span>
<hr className='hrSm' />
</div>

<div className='detailsWrap-inv'>
<div className='order-detailsInv'>
<div className='details-col'><span>User :</span></div>
<div className='details-col'><span>{data.user}</span></div>
</div>
<div className='order-detailsInv'>
<div className='details-col'><span>Contact :</span></div>
<div className='details-col'><span>{data.contact}</span></div>
</div>
<div className='order-detailsInv'>
<div className='details-col'><span>Payment By :</span></div>
<div className='details-col'><span>{data.paymentBy}</span></div>
</div>
<div className='order-detailsInv'>
<div className='details-col'><span>Payment Status :</span></div>
<div className='details-col'><span>{data.payStatus}</span></div>
</div>
<div className='order-detailsInv'>
<div className='details-col'><span>Payment Option :</span></div>
<div className='details-col'><span>{data.payOpt}</span></div>
</div>
</div>

<div className='invoiceFooter'>
<img src='../images/hands.png' alt='hands' />
<span>Thanks For Visiting</span>
<hr className='hrSm2' />
<span>A restaurant is a place where you go to celebrate a special occasion, or to create a new one.</span>
</div>

</div>

</div>
</div>
)
})
}
</div>
</div>

<div className='modalFooter'>
<div className='modalFootCon'>
<button onClick={handlePrint}><i className='fa fa-print'></i> Print Invoice</button>
</div>
</div>

</div>
</div>
</>
)
}

export default InvoiceView;
