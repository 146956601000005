import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const OrderStatus = () => {

const CloseModal = () => {
$('#orderStatusModal').hide();   
}

const updateOrderStat = async() => {
var id = $('#orderId_prd').val();
var status = $('#orderStatus_prd').val();
//alert(id +' -- '+ status);
if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Order status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/update-order-status', {id: id, status: status});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Order status updated');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
} catch (error) {
console.log('backend update-order-status-api error: ' + error);
}
}
}

return (
<>
<div className='modals' id='orderStatusModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-upload'></i> Order Status <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='input-div'>
<label>Order Status :</label>
<input type='hidden' id='orderId_prd' />
<select id='orderStatus_prd'>
<option value='null'>---select order status---</option>
<option value='proccessing'>proccessing</option>
<option value='confirmed'>confirmed</option>
<option value='order placed'>order placed</option>
<option value='cancelled'>cancelled</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => updateOrderStat()}>Update</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default OrderStatus;
