import React from 'react';
import { Link } from 'react-router-dom';
import { GlobalData } from '../context/AppContext';
import OrderPending from './OrderPending';
import OrderConfirm from './OrderConfirm';
import OrderPlaced from './OrderPlaced';

const Dashboard = () => {
const { productData, customers, allOrders, orderPrd, taxData, couponData } = GlobalData();

const reduceData = orderPrd.length > 0 && orderPrd.reduce((curr, elem) => {
const { itemPrice, itemQuantity, itemStock, payStatus, couponName, orderId } = elem;
let newPrc = 0;
if (payStatus === 'completed'){
const item = curr.find((p) => p.couponName === couponName && p.orderId === orderId);
if (itemQuantity === 'half'){
newPrc = (parseFloat(itemPrice) * parseInt(itemStock)) / 2;
}
else{
newPrc = parseFloat(itemPrice) * parseInt(itemStock);  
}
item ? item.newPrc += newPrc : curr.push({orderId, couponName, newPrc});
}
return curr;
},[]);

const reduceVal = reduceData && reduceData.reduce((c, v) => {
const { newPrc } = v;
c += newPrc;
return c;
},0);

const taxReduce = taxData.length > 0 && taxData.reduce((acc, el) => {
const { rate } = el;
acc += (parseFloat(reduceVal) * parseFloat(rate)) / 100;
return acc;
},0);

var checkCou = [];

if(reduceData && couponData.length > 0){
for(let i = 0; i < reduceData.length; i++){
for(let j = 0; j < couponData.length; j++){
if(reduceData[i].couponName === couponData[j].name){
var data = {
couponName : reduceData[i].couponName,
newPrc: reduceData[i].newPrc,
rate: couponData[j].rate,
type: couponData[j].type
}
checkCou.push(data);
}
}
}
}

const couponVal = checkCou.length > 0 && checkCou.reduce((i, k) => {
const { rate, type, newPrc } = k;
let prc = 0;
if (type === 'percent'){
prc = (parseFloat(newPrc) * parseFloat(rate)) / 100;
}
else{
prc = rate;
}
return i += prc;
},0);

let totalSell = 0;
if(reduceData){
totalSell = ((parseFloat(reduceVal) + parseFloat(taxReduce)) - parseFloat(couponVal));
}

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Dashboard</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / Dashboard</span>
</div>
</div>

<div className='content-wrap'>
<div className='rows'>
<div className='fourCol'>
<Link to='/all-reports'>
<div className='cards'>
<div className='cards-body'>
<img src='/images/sale.gif' alt='cardimg' />
<span>Total Sales</span>
<span>₹ {totalSell.toFixed(2)}</span>
</div>
</div>
</Link>
</div>

<div className='fourCol'>
<Link to='/add-product'>
<div className='cards'>
<div className='cards-body'>
<img src='/images/salad.gif' alt='cardimg' />
<span>Total Products</span>
<span>{productData.length}</span>
</div>
</div>
</Link>
</div>

<div className='fourCol'>
<Link to='/all-orders'>
<div className='cards'>
<div className='cards-body'>
<img src='/images/waiter.gif' alt='cardimg' />
<span>Total Orders</span>
<span>{allOrders.length}</span>
</div>
</div>
</Link>
</div>

<div className='fourCol'>
<Link to='/all-customer'>
<div className='cards'>
<div className='cards-body'>
<img src='/images/customer.gif' alt='cardimg' />
<span>Total Customers</span>
<span>{customers.length}</span>
</div>
</div>
</Link>
</div>
</div>

<OrderPending />
<OrderConfirm />
<OrderPlaced />
</div>

</div>
</div>

</>
)
}

export default Dashboard;
