import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';

const APModal = () => {
const { categoryData, couponData } = GlobalData();
const [files, setFiles] = useState([]);
const [catType, setCatType] = useState([]);

const CloseModal = () => {
$('#addProductModal').hide();
}

const handleFile = (e) => {
setFiles(e.target.files[0]);   
}

const addsProducts = async() => {
var formData = new FormData();
var category = $('#category-product').val();
var itemType = $('#item-type').val();
var product = $('#product-name').val(); 
var discription = $('#product-discription').val();
var price = $('#product-price').val();
var coupon = $('#coupon-product').val();
//console.log(category +' -- ' + product);
if(category === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Product category required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(product === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Product name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(price === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Product price required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
formData.append('productImage', files);
formData.append('productCategory', category);
formData.append('itemType', itemType);
formData.append('productName', product);
formData.append('discription', discription);
formData.append('prdprice', price);
formData.append('coupon', coupon);
try {
const sendData = await axios.post('/node-server/add-products', formData, {
headers: {'Content-Type' : 'multipart/form-data'}
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Product created successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;    
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}

} catch (error) {
console.log('backend add-product-api error: ' + error);  
}
}
}

const handlePrice = (e) => {
if(e.target.value < 1){
$('#product-price').val(0);
}   
}

const handleCatOpt = (catsName) => {
setCatType(categoryData.filter((item) => item.name === catsName));
}

return (
<>
<div className='modals' id='addProductModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-plus'></i> Add Product <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='form-dataDiv'>
<div className='input-div'>
<label>Product Image: </label>
<input type='file' onChange={(e) => handleFile(e)} />
</div>
<div className='input-div'>
<label>Product Category: </label>
<select id='category-product' onChange={(e) => handleCatOpt(e.target.value)}>
<option value='null'>--Select Category--</option>
{
categoryData !== undefined && categoryData.map((data, index) => {
return (
<option value={data.name} key={index}>{data.name}</option>
)
})  
}
</select>
</div>
<div className='input-div'>
<label>Item Type: </label>
<input type='text' id='item-type' placeholder='Item Type' value={catType.length > 0 && catType[0].types} disabled />
</div>
<div className='input-div'>
<label>Product Coupon: </label>
<select id='coupon-product'>
<option value='null'>--Select Coupon--</option>
{
couponData !== undefined && couponData.map((data, index) => {
return (
<option value={data.name} key={index}>{data.name}</option>
)
})  
}
</select>
</div>
<div className='input-div'>
<label>Product Name: </label>
<input type='text' id='product-name' placeholder='Enter Product Name...' />
</div>
<div className='input-div'>
<label>Product Discription: </label>
<textarea id='product-discription' placeholder='Enter Product Discription...' />
</div>
<div className='input-div'>
<label>Product Price: </label>
<input type='number' id='product-price' placeholder='Enter Product Price...' onChange={handlePrice} />
</div>
<div className='input-div-submit'>
<button onClick={() => addsProducts()}><i className='fa fa-plus'></i> Add Product</button>
</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default APModal;
