import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const PaymentStatus = () => {

const CloseModal = () => {
$('#orderPayModal').hide();   
}

const updateOrderPay = async() => {
var id = $('#orderId_pay').val();
var payBy = $('#orderStatus_pay').val();
var status;
//alert(id +' -- '+ status);
if (payBy === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Payment status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;   
}
else{
if (payBy === 'cancelled'){
status = 'cancelled';   
}
else{
status = 'completed';      
}
try {
const sendData = await axios.post('/node-server/update-order-payment', {id: id, payBy: payBy, status: status});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Payment status updated');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;   
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend order-payment-status-api error: ' + error);
}   
}
}

return (
<>
<div className='modals' id='orderPayModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-upload'></i> Payment Status <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='input-div'>
<label>Payment Status :</label>
<input type='hidden' id='orderId_pay' />
<select id='orderStatus_pay'>
<option value='null'>---select payment status---</option>
<option value='cash'>cash</option>
<option value='credit card'>credit card</option>
<option value='debit card'>debit card</option>
<option value='upi'>upi</option>
<option value='cancelled'>cancelled</option>
<option value='others'>others</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => updateOrderPay()}>Update</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default PaymentStatus;
