import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';
import OrderStatus from '../modals/OrderStatus';
import PaymentStatus from '../modals/PaymentStatus';
import AddMore from '../modals/AddMore';
import EditOrder from '../modals/EditOrder';
import RemoveOrder from '../modals/RemoveOrder';

const ViewOrders = () => {
const { id } = useParams();
const { orderPrd, taxData, couponData, GetPrdOrder } = GlobalData();
const [filterData, setFilterData] = useState();

const reduceData = filterData && filterData.reduce((curr, elem) => {
const { contact, dateTime, itemQuantity, itemStock, itemPrice, orderId, orderStatus, payOpt, tabelNo, user, paymentBy, payStatus, couponName } = elem;
const item = curr.find((it) => it.orderId === orderId);
let newPrc = 0;
if(itemQuantity === 'half'){
newPrc = (parseFloat(itemPrice) * parseInt(itemStock)) / 2;
}
else{
newPrc = parseFloat(itemPrice) * parseInt(itemStock);
}
item ? item.newPrc += newPrc : curr.push({orderId, contact, dateTime, newPrc, orderStatus, payOpt, tabelNo, user, paymentBy, payStatus, couponName});
return curr;
},[]);

const orderStatus = (id) => {
$('#orderStatusModal').fadeIn().show();  
$('#orderId_prd').val(id);
}

const paymentStatus = (id) => {
$('#orderPayModal').fadeIn().show(); 
$('#orderId_pay').val(id);
}

const addMoreItem = (id,name,contact,tabelNo,dateTime) => {
$('#addMoreModal').fadeIn().show(); 
$('#orderId_Item').val(id);
$('#order_user').val(name);
$('#order_contact').val(contact);
$('#order_tabel').val(tabelNo);
$('#order_date').val(dateTime);
}

const editItemsOrder = (id,prc,name) => {
$('#orderEditModal').fadeIn().show(); 
$('#orderId_edit').val(id);
$('#orderPrc').val(prc);
$('#orderItemName').val(name);
}

const removeItemsOrder = (id) => {
$('#removeOrderItem').fadeIn().show();
$('#removeId').val(id);  
}

const allListCou = () => {
$('#allCou').toggle();
}

const applyCou = async(nam) => {
try {
const sendData = await axios.post('/node-server/apply-coupon', {id: id, name: nam});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Coupon applied successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
GetPrdOrder('/allitems-orders');
},1000);
return false;
}
else if (resData === 'removed'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Change coupon successfull');   
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
GetPrdOrder('/allitems-orders');
},1000);
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend apply-coupon-api error: ' + error);
}
}

useEffect(() => {
setFilterData(orderPrd.filter((item) => item.orderId === id));
},[id, orderPrd]);

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Orders</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / View Orders</span>
</div>
</div>

{
reduceData && reduceData.map((data1, index1) => {
return(
<div className='orders-options' key={index1}>
<button onClick={() => orderStatus(data1.orderId)}>Order Status</button>
<button onClick={() => paymentStatus(data1.orderId)}>Payment Status</button>
<button onClick={() => addMoreItem(data1.orderId,data1.user,data1.contact,data1.tabelNo,data1.dateTime)}>Add Items</button>
</div>
)
})
}

<div className='coupon-section'>
<div className='coupon-secWrap'>
<div className='CouponHead' onClick={() => allListCou()}>
<div className='col'>
<img src='../images/coupon.gif' alt='coupon' />
<span>Available Coupons</span>
</div>
<div className='col'>
<img src='../images/discount.gif' alt='discount' />
</div>
</div>

<div id='allCou'>
<div className='colCouWrap'>
{
couponData.length > 0 ? couponData.map((dat, ind) => {

reduceData && reduceData.map((c) => {
if(c.couponName === dat.name){
$('#applyCbtn_'+dat.name).html('Applied').css('background', 'teal');
}
else{
$('#applyCbtn_'+dat.name).html('Apply').css('background', 'crimson'); 
}
return null;
});

return (
<div className='colCou' key={ind}>
<div>
<span>{dat.name} Coupon</span>
<span>Discount : {dat.type === 'percent' ? '('+ dat.rate +' %)' : '('+ dat.rate +' ₹)'}</span>
<span>Coupon Type : {dat.type}</span>
<button id={'applyCbtn_'+dat.name} onClick={() => applyCou(dat.name)}>Apply</button>
</div>
</div>
)
})
:
<div className='colCou'>
<div><span>No Coupon Available</span></div>
</div>
}
</div>
</div>
</div>
</div>

<div className='products-page'>
{
reduceData && reduceData.map((data, index) => {

const taxReduce = taxData.length > 0 && taxData.reduce((acc, ell) => {
const { rate } = ell;
acc += (parseFloat(data.newPrc) * parseFloat(rate)) / 100;
return acc;
},0);

const couponVal = couponData.length > 0 && couponData.filter((e) => e.name === data.couponName).reduce((e, f) => {
const { type, rate } = f;
if (type === 'percent'){
e = (parseFloat(data.newPrc) * parseFloat(rate)) / 100; 
}
else{
e = rate;
}
return e;
},0);

return (
<div className='details-cards' key={index}>
<div className='ordersDetails'>

<div className='ordersDiv'>
<span className='product-title'>Item Details</span>
<hr className='hr1' />
{
orderPrd.length > 0 && orderPrd.filter((item) => item.orderId === data.orderId).map((data2, index2) => {
var itemType;
var halfPrc = 0;
var fullPrc = 0;
var currItemPrc = 0;
if(data2.itemTypes === 'veg'){
itemType = <span className='vegSpan'><span></span></span>;    
}
else{
itemType = <span className='nonvegSpan'><span></span></span>;  
}

halfPrc = parseFloat(data2.itemPrice) / 2;    
fullPrc = parseFloat(data2.itemPrice);

if (data2.itemQuantity === 'half'){
currItemPrc = <span>Order Price = {data2.itemStock +' X ₹'+ halfPrc +' = ₹'+ parseFloat(halfPrc) * data2.itemStock}</span>
}
else{
currItemPrc = <span>Order Price = {data2.itemStock +' X ₹'+ fullPrc +' = ₹'+ parseFloat(fullPrc) * data2.itemStock}</span>
}

return (
<div className='ordersDivWrapItem' key={index2}>
<div className='itemsNames'>
{itemType}
<span className='itemsTitle'>{data2.itemStock} X {data2.itemName} <span>({data2.itemQuantity})</span></span>
<span className='edit-icon' onClick={() => editItemsOrder(data2.id,fullPrc,data2.itemName)}><i className='fa fa-edit'></i></span>
<span className='removeSpan'  onClick={() => removeItemsOrder(data2.id)}><i className='fa fa-trash'></i></span>
</div>
<div className='itemprcx'>
<span>Full Price : ₹{fullPrc} /</span>
<span>Half Price : ₹{halfPrc} /</span>
{currItemPrc}
</div>
</div>
)
})
}
</div>

<div className='itemsFooter'>
<div><span>Order Status : </span> <span>{data.orderStatus}</span></div>
<div><div><span>Total Price : </span> <span>₹ {((parseFloat(data.newPrc) + parseFloat(taxReduce)) - parseFloat(couponVal)).toFixed(2)}</span></div></div>
</div>

{
couponData.length > 0 && couponData.filter((ex) => ex.name === data.couponName).map((exx, indd) => {
return (
<div className='couponStatus' key={indd}>
<div>
<img src='../images/coupon.gif' alt='coupon' />
<span>{exx.name} Coupon ({exx.type === 'percent' ? exx.rate +'%' : exx.rate +'₹'})</span>
</div>
<div>
<span>Coupon Applied</span>
<img src='../images/double-check.gif' alt='double-check' />
</div>
</div>
)
}) 
}

<div className='ordersDiv'>
<span className='product-title'>Billing Details</span>
<hr className='hr1' />
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Price :</span></div>
<div className='ordersDivCol'><span>₹ {data.newPrc.toFixed(2)}</span></div>
</div>
{
couponData.length > 0 && couponData.filter((e) => e.name === data.couponName).map((cou, ind) => {
let couDis = 0;
if(cou.type === 'percent'){
couDis = (parseFloat(data.newPrc) * parseInt(cou.rate)) / 100;
}
else{
couDis = cou.rate;
}
return (
<div className='ordersDivWrap' key={ind}>
<div className='ordersDivCol'><span>Coupon Discount :</span></div>
<div className='ordersDivCol'><span>₹ -{couDis.toFixed(2)}</span></div>
</div>    
)
})
}

{
taxData.length > 0 && taxData.map((data3, index3) => {
let a = 0;
a = (parseFloat(data.newPrc) * parseFloat(data3.rate)) / 100;
return (
<div className='ordersDivWrap' key={index3}>
<div className='ordersDivCol'><span>{data3.name +' ('+ data3.rate + '%)'} :</span></div>
<div className='ordersDivCol'><span>₹ {parseFloat(a).toFixed(2)}</span></div>
</div>
)
})
}
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Total Price :</span></div>
<div className='ordersDivCol'><span>₹ {((parseFloat(data.newPrc) + parseFloat(taxReduce)) - parseFloat(couponVal)).toFixed(2)}</span></div>
</div>
</div>

<div className='ordersDiv'>
<span className='product-title'>Order Details</span>
<hr className='hr1' />
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Order Id :</span></div>
<div className='ordersDivCol'><span>{data.orderId}</span></div>
</div>
{
data.payOpt === 'tabel_order' &&
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Order Tabel :</span></div>
<div className='ordersDivCol'><span>{data.tabelNo}</span></div>
</div>
}
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Payment :</span></div>
<div className='ordersDivCol'><span>{data.paymentBy}</span></div>
</div>
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Payment Status:</span></div>
<div className='ordersDivCol'><span>{data.payStatus}</span></div>
</div>
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Order Date :</span></div>
<div className='ordersDivCol'><span>{data.dateTime}</span></div>
</div>
</div>

<div className='ordersDiv'>
<span className='product-title'>User Details</span>
<hr className='hr1' />
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Customer Name :</span></div>
<div className='ordersDivCol'><span>{data.user}</span></div>
</div>
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Conatct No :</span></div>
<div className='ordersDivCol'><span>{data.contact}</span></div>
</div>
</div>
</div>
</div>
)
})
}
</div>

</div>
</div>

<OrderStatus />
<PaymentStatus />
<AddMore />
<EditOrder />
<RemoveOrder />
</>
)
}

export default ViewOrders;
