import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalData } from '../context/AppContext';
import axios from 'axios';

const EAPModal = ({data}) => {
const { categoryData, couponData } = GlobalData();
const [getedit, setGetEdit] = useState([]);

const CloseModal = () => {
$('#editProductModal').hide();
setGetEdit([]);
}

const updateProduct = async() => {
var id = $('#edit-productId').val();
var name = $('#edit-productName').val();
var category = $('#editCategory').val();
var itemType = $('#EditItemType').val();
var price = $('#edit-productPrice').val();
var discription = $('#edit-productDis').val();
var stock = $('#edit-stock').val();
var coupon = $('#editCoupon').val();

//alert(id +' -- '+ name +' -- '+ category +' -- '+ price);
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Product name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
},1000);
return false;
}
if(category === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Category required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
},1000);
return false;
}
if(price === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Price required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
},1000);
return false;
}
if(discription === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Discription required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
},1000);
return false;
}
if(stock === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Stock required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/update-product', {id: id, name: name, category: category, itemType: itemType, price: price, discription: discription, stock: stock, coupon: coupon});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Product updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
window.location.reload();
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow'); 
},1000);
}
} catch (error) {
console.log('backend product-update-api error: ' + error);
}
}
}

const handleEditCat = (val) => {
const matchVal = categoryData.filter((item) => item.name === val);
$('#EditItemType').val(matchVal[0].types);
}


useEffect(() => {
setGetEdit(data);
},[data]);

return (
<>
<div className='modals' id='editProductModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-edit'></i> Edit Product <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>

{
getedit.length > 0 &&
<div>

<div className='input-div'>
<label>Product Name</label>
<input type='text' id='edit-productName' defaultValue={getedit[0].name} />
<input type='hidden' id='edit-productId' defaultValue={getedit[0].id} />
</div>

<div className='input-div'>
<label>Product Category</label>
<select id='editCategory' onChange={(e) => handleEditCat(e.target.value)}>
<option value={getedit[0].category}>{getedit[0].category}</option>
<option value='null'>--select category--</option>
{
categoryData.length > 0 && categoryData.map((data, index) => {
return (
<option value={data.name} key={index}>{data.name}</option>
)
})
}
</select>
</div>
<div className='input-div'>
<label>Item Type: </label>
<input type='text' id='EditItemType' placeholder='Item Type' value={getedit[0].types} disabled />
</div>
<div className='input-div'>
<label>Product Coupon</label>
<select id='editCoupon'>
<option value={getedit[0].coupon !== null  && getedit[0].coupon !== 'null' ? getedit[0].coupon : 'no-coupon'}>{getedit[0].coupon !== null  && getedit[0].coupon !== 'null' ? getedit[0].coupon : 'no-coupon'}</option>
<option value='null'>--select coupon--</option>
{
couponData.length > 0 && couponData.map((data, index) => {
return (
<option value={data.name} key={index}>{data.name}</option>
)
})
}
</select>
</div>

<div className='input-div'>
<label>Product Price</label>
<input type='text' id='edit-productPrice' defaultValue={getedit[0].price} />
</div>

<div className='input-div'>
<label>Product Discription</label>
<textarea type='text' id='edit-productDis' defaultValue={getedit[0].discription} />
</div>

<div className='input-div'>
<label>Stock</label>
<select id='edit-stock'>
<option value={getedit[0].stock}>{getedit[0].stock === 'available' ? 'In Stock' : 'Out Of Stock'}</option>
<option value='null'>--select-stock--</option>
<option value='available'>In Stock</option>
<option value='unavailable'>Out Of Stock</option>
</select>
</div>

<div className='input-div-submit'>
<button onClick={() => updateProduct()}><i className='fa fa-upload'></i> Update</button>
</div>
</div>
}

</div>
</div>

</div>
</div>
</>
)
}

export default EAPModal;
