import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const RemoveUser = () => {

const closeRemove = () => {
$('#removeUserModal').hide();
}

const deleteCustomer = async() => {
var id = $('#removeId').val();
try {
const sendData = await axios.post('/node-server/removeAdmin-customer', {id: id});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Customer removed successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend remove-customer-api error: ' + error); 
}
}

return (
<>
<div className='modals' id='removeUserModal'>
<div className='modals-content-remove'>
<div className='modals-haeder'><i className='fa fa-trash'></i> Remove Customer <i className='fa fa-close rightModalIcon' onClick={() => closeRemove()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<center><img src='../images/trash-bin.gif' alt='trash' id='editCatImg' />
<input type='hidden' id='removeId' />
<span>Are you sure? You want to remove this customer?</span></center>
<div className='yesNoDiv'>
<button onClick={() => deleteCustomer()}>Yes</button>
<button onClick={() => closeRemove()}>No</button>
</div>
</div>
</div>

</div>
</div> 
</>
)
}

export default RemoveUser;
