import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import ATModal from '../modals/ATModal';
import ETModal from '../modals/ETModal';
import RTModal from '../modals/RTModal';
import { GlobalData } from '../context/AppContext';

const AddTax = () => {

const { taxData } = GlobalData();
const [editTaxes, setEditTaxes] = useState([]);
const [currpage, setPage] = useState(1);
const [fcategory, setCategory] = useState([]);
const [sortpage, setSort] = useState(50);
const recordPage = sortpage;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);

const add_tax = () => {
$('#addTaxModal').fadeIn().show();
}

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const SearchFilter = (e) => {
setCategory(taxData.filter(a => a.name.toLowerCase().includes(e.target.value)));
}

const FilterSort = (e) => {
setSort(e.target.value);
}

const editTax = (id) => {
setEditTaxes(taxData.filter((item) => item.id === id));
$('#editTaxModal').fadeIn().show();
}

const deleteTax = (id) => {
$('#removeTaxModal').fadeIn().show();
$('#removeId').val(id);
}

useEffect(() => {
if(taxData){
setCategory(taxData);
}
},[taxData]);

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Tax</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / Add Tax</span>
</div>
</div>

<div className='content-wrap'>
<div className='content-top-button'>
<button onClick={() => add_tax()}><i className='fa fa-plus'></i> Add Tax</button>
</div>

<div className='contentBody'>
<div className='body-wrap-content'>

<div className='card-bodysingle'>

<div className='search-div-wrap'>
<div className='sort-div'>
<label>Sort By Page:</label>
<select onChange={FilterSort}>
<option>50</option>
<option>100</option>
<option>250</option>
<option>500</option>
</select>
</div>

<div className='input-search-div'>
<input type='text' placeholder='Search Tax...' onChange={SearchFilter} />
</div>
</div>

<div className='tabel-Responsive'>
<table className='tabelSmall'>
<thead>
<tr>
<th>Id</th>
<th>Tax Name</th>
<th>Rate</th>
<th>Status</th>
<th>Action</th>
</tr>
</thead>
{
records.length > 0 ? records.map((data, index) => {
var taxStatus = 'disable';
if(data.status === 'enable'){
taxStatus = <span className='instock'>Enable</span>;
}
else{
taxStatus = <span className='outstock'>Disable</span>;
}
return (
<tbody key={index}>
<tr>
<td>{data.id}</td>
<td>{data.name}</td>
<td>{data.rate}%</td>
<td>{taxStatus}</td>
<td>
<button className='smBtn' onClick={() => editTax(data.id)}><i className='fa fa-cog'></i></button>
<button className='smBtn' onClick={() => deleteTax(data.id)}><i className='fa fa-trash'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='5'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>


<div className='pagination-wrap'>
<div className='pagination-div'><span className='totalData'>Total Records: {fcategory.length}</span></div>

<div className='pagination-div'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>
</div>

</div>
</div>


</div>
</div>

</div>
</div>

<ATModal />
<ETModal datas={editTaxes} />
<RTModal />
</>
)
}

export default AddTax;
