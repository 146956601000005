import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalData } from '../context/AppContext';
import InvoiceView from '../modals/InvoiceView';

const Invoice = () => {

const { orderPrd, taxData, couponData } = GlobalData();
const [filterD, setFilterD] = useState([]);
const [currpage, setPage] = useState(1);
const [sortpage, setSort] = useState(50);
const [fcategory, setCategory] = useState([]);
const recordPage = sortpage;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const SearchFilter = (e) => {
setCategory(orderPrd.filter(a => a.payStatus.toLowerCase().includes(e.target.value)));
}

const FilterSort = (e) => {
setSort(e.target.value);
}

const reduceData = records.length > 0 && records.reduce((curr, elem) => {
const { user, contact, dateTime, orderId, payStatus, currPrc, itemStock, couponName } = elem;
const item = curr.find((it) => it.orderId === orderId);
let newPrc = parseFloat(currPrc) * parseInt(itemStock);
item ? item.newPrc += newPrc : curr.push({user,contact,dateTime,orderId,payStatus,newPrc,itemStock,couponName});
return curr;
},[]);

const invoiceOpen = (id) => {
setFilterD(orderPrd.filter((e) => e.orderId === id));
$('#invoiceModal').fadeIn().show();
}

useEffect(() => {
if (orderPrd.length > 0){
setCategory(orderPrd); 
}
},[orderPrd]);
return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Invoice List</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / All Invoice</span>
</div>
</div>

<div className='content-wrap'>
<div className='card-bodysingle'>

<div className='search-div-wrap'>
<div className='sort-div'>
<label>Sort By Page:</label>
<select onChange={FilterSort}>
<option>50</option>
<option>100</option>
<option>250</option>
<option>500</option>
</select>
</div>

<div className='input-search-div'>
<input type='text' placeholder='Search Invoice Status...' onChange={SearchFilter} />
</div>
</div>

<div className='tabel-Responsive'>
<table className='tabelLarge'>
<thead>
<tr>
<th>Order Id</th>
<th>User</th>
<th>Contact</th>
<th>Pay Status</th>
<th>Price</th>
<th>Order Date</th>
<th>Action</th>
</tr>
</thead>
{
reduceData.length > 0 ? reduceData.map((data, index) => {

const taxReduce = taxData.length > 0 && taxData.reduce((acc, el) => {
const { rate } = el;
acc += (parseFloat(data.newPrc) * parseFloat(rate)) / 100;
return acc;
},0);

const couponVal = couponData.length > 0 && couponData.filter((e) => e.name === data.couponName).reduce((e, f) => {
const { type, rate } = f;
if (type === 'percent'){
e = (parseFloat(data.newPrc) * parseFloat(rate)) / 100; 
}
else{
e = rate;
}
return e;
},0);

let newPrc = 0;
newPrc = ((parseFloat(data.newPrc) + parseFloat(taxReduce)) - parseFloat(couponVal));

return (
<tbody key={index}>
<tr>
<td>{data.orderId}</td>
<td>{data.user}</td>
<td>{data.contact}</td>
<td>{data.payStatus}</td>
<td>{newPrc.toFixed(2)}</td>
<td>{data.dateTime}</td>
<td>
<button onClick={() => invoiceOpen(data.orderId)} className='smBtn'><i className='fa fa-eye'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='8'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>


<div className='pagination-wrap'>
<div className='pagination-div'><span className='totalData'>Total Records: {reduceData.length}</span></div>

<div className='pagination-div'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>
</div>

</div>

</div>

</div>
</div>

<InvoiceView data={filterD} />
</>
)
}

export default Invoice;
