import React from 'react';

const ErrorPage = () => {
return (
<>
<div className='content-body'>
<div className='conatiner-md'>
<h1>page not found....</h1>
</div>
</div>

</>
)
}

export default ErrorPage;
