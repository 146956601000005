import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const EditOrder = () => {

const CloseModal = () => {
$('#orderEditModal').hide();
}

const handleItems = (e) => {
if (e.target.value < '1'){
$('#itemNos').val('1');   
}
else{
$('#itemNos').val(e.target.value);   
}
}

const submitEdit = async() => {
var id = $('#orderId_edit').val();
var prc = $('#orderPrc').val();
var quantity = $('#editQuan').val();
var itemNo = $('#itemNos').val();
var currPrc = 0;
//alert(id +' -- '+ quantity +' -- '+ itemNo);
if (quantity === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Quantity required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
if (quantity === 'half') {
currPrc = (parseFloat(prc) * parseInt(itemNo)) / 2;   
}
else{
currPrc = parseFloat(prc) * parseInt(itemNo);    
}
try {
const sendData = await axios.post('/node-server/edit-order', {id: id, currPrc: currPrc, quantity: quantity, itemNo: itemNo});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Item edited successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;   
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend order-edit-api error: ' + error);   
}
}
}

return (
<>
<div className='modals' id='orderEditModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-edit'></i> Edit Order <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<div className='input-div'>
<input type='hidden' id='orderId_edit' />
<input type='hidden' id='orderPrc' />
<label>Item Name :</label>
<input type='text' id='orderItemName' disabled />
</div>
<div className='input-div'>
<label>Select Quantity :</label>
<select id='editQuan'>
<option value='null'>---select quantity---</option>
<option value='half'>Half</option>
<option value='full'>Full</option>
</select>
</div>
<div className='input-div'>
<label>Item No :</label>
<input type='number' id='itemNos' placeholder='Item number...' onChange={handleItems} defaultValue='1' />
</div>
<div className='input-div-submit'>
<button onClick={() => submitEdit()}>Submit</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default EditOrder;
