import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import AddCateoryModal from '../modals/AddCateoryModal';
import EditCategoryModal from '../modals/EditCategoryModal';
import RCModal from '../modals/RCModal';
import { GlobalData } from '../context/AppContext';

const AddCategory = () => {

const { categoryData } = GlobalData();
const [currpage, setPage] = useState(1);
const [fcategory, setCategory] = useState([]);
const [sortpage, setSort] = useState(50);
const recordPage = sortpage;
const lastindex = currpage * recordPage;
const firstindex = lastindex - recordPage;
const records = fcategory.slice(firstindex, lastindex);
const pagelength = Math.ceil(fcategory.length / recordPage);
const pageNumber = [...Array(pagelength + 1).keys()].slice(1);

const prevPage = () => {
if(currpage !== 1) {
setPage(currpage - 1);
}  
}

const nextPage = () => {
if(currpage !== pagelength) {
setPage(currpage + 1);
}  
}

const numberPage = (id) => {
setPage(id);
}

const SearchFilter = (e) => {
setCategory(categoryData.filter(a => a.name.toLowerCase().includes(e.target.value)));
}

const FilterSort = (e) => {
setSort(e.target.value);
}

const openCategoryModal = () => {
$('#addCategoryModal').fadeIn().show()
}

const editCategory = (id,name,types,img) => {
 $('#editCategoryModal').fadeIn().show(); 
 $('#editCatId').val(id); 
 $('#editCatImg').attr("src", img);
 $('#editCatName').val(name);
 $('#editCatType').val(types); 
}

const removeCategory = (id) => {
$('#removeCategoryModal').fadeIn().show();
$('#removeId').val(id);
}

useEffect(() => {
if(categoryData !== undefined){
setCategory(categoryData);
}
},[categoryData]);

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Category</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / Add Category</span>
</div>
</div>

<div className='content-wrap'>
<div className='content-top-button'>
<button onClick={() => openCategoryModal()}><i className='fa fa-plus'></i> Add Category</button>
</div>

<div className='contentBody'>
<div className='body-wrap-content'>

<div className='card-bodysingle'>

<div className='search-div-wrap'>
<div className='sort-div'>
<label>Sort By Page:</label>
<select onChange={FilterSort}>
<option>50</option>
<option>100</option>
<option>250</option>
<option>500</option>
</select>
</div>

<div className='input-search-div'>
<input type='text' placeholder='Search Category...' onChange={SearchFilter} />
</div>
</div>

<div className='tabel-Responsive'>
<table className='tabelSmall'>
<thead>
<tr>
<th>Id</th>
<th>Name</th>
<th>Type</th>
<th>Image</th>
<th>Action</th>
</tr>
</thead>
{
records.length > 0 ? records.map((data, index) => {
return (
<tbody key={index}>
<tr>
<td>{data.id}</td>
<td>{data.name}</td>
<td>{data.types}</td>
<td><img src={data.path} className='tablesmimg' alt='imagesCat' /></td>
<td>
<button className='smBtn' onClick={() => editCategory(data.id,data.name,data.types,data.path)}><i className='fa fa-cog'></i></button>
<button className='smBtn' onClick={() => removeCategory(data.id)}><i className='fa fa-trash'></i></button>
</td>
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='5'>No records found...</td>
</tr>
</tbody>
}
</table>
</div>


<div className='pagination-wrap'>
<div className='pagination-div'><span className='totalData'>Total Records: {fcategory.length}</span></div>

<div className='pagination-div'>
<ul className="pagination">
<li className="page-item"><button className="page-link" onClick={() => prevPage()}>Previous</button></li>
{
pageNumber.map((data, index) => (
<li className="page-item" key={index}><button className="page-link" onClick={() => numberPage(data)}>{data}</button></li>
))
}
<li className="page-item"><button className="page-link"  onClick={() => nextPage()}>Next</button></li>
</ul>
</div>
</div>

</div>
</div>


</div>
</div>

</div>
</div>
<AddCateoryModal />
<EditCategoryModal />
<RCModal />
</>
)
}

export default AddCategory;
