import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';

const TabelStatus = ({data}) => {

const [statusFilter, setStatusFilter] = useState([]);

const CloseModal = () => {
$('#editStatusTabelModal').hide();
setStatusFilter([]);
}

const updateTabelStatus = async() => {
var id = $('#statusIdTabel').val();
var status = $('#editTabelStatus').val();
var tabelCurr = $('#tabelCurr').val();
if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Status required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(tabelCurr === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Tabel error status');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/update-tabel-status', {id: id, status: status, tabelCurr: tabelCurr});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Status updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);  
}
} catch (error) {
console.log('backend update-tabel-status-api error: ' + error);
}
}

}

useEffect(() => {
if(data.length > 0){
setStatusFilter(data);   
}
},[data]);

return (
<>
<div className='modals' id='editStatusTabelModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-cog'></i> Setting Status <i className='fa fa-close rightModalIcon' onClick={() => CloseModal()}></i></div>

<div className='modals-body'>
{
statusFilter.length > 0 && statusFilter.map((data, index) => {
return (
<div className='body-content' key={index}>
<div className='input-div'>
<label>Tabel No :</label>
<input type='hidden' id='statusIdTabel' defaultValue={data.id} />
<input type='text' id='tabelCurr' defaultValue={data.tabelNo} disabled />
</div>
<div className='input-div'>
<label>Current Status :</label>
<input type='text' defaultValue={data.status} disabled />
</div>
<div className='input-div'>
<label>Status :</label>
<select id='editTabelStatus'>
<option value={data.status}>{data.status}</option>
<option value='null'>---select status---</option>
<option value='active'>Active</option>
<option value='inactive'>In-Active</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => updateTabelStatus()}><i className='fa fa-cog'></i> Update Status</button>
</div>
</div>
)
})
}
</div>

</div>
</div>
</>
)
}

export default TabelStatus;
