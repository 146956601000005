import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalData } from '../context/AppContext';

const SettingSocial = () => {

const { storeSocial } = GlobalData();

const updateSocial = async() => {
var id = storeSocial.length > 0 && storeSocial[0].id;
var fb = $('#storeFacebook').val();
var insta = $('#storeInstagram').val();
var twitt = $('#storeTwitter').val();
var ytube = $('#storeYouTube').val();
try {
const sendData = await axios.post('/node-server/updateStore-social', {id:id, fb:fb, insta:insta, twitt:twitt, ytube:ytube});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Social updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('server social-update-api error: ' + error);
}
}

return (
<>
<div className='content-body'>
<div className='conatiner-md'>

<div className='page-titlediv'>
<div className='page-titles'>
<span>Edit Social Setting</span>
</div>
<div className='page-titles'>
<span><i className='fa fa-home'></i> Home / Social Setting</span>
</div>
</div>

<div className='content-wrap'>
{
storeSocial.length > 0 && storeSocial.map((data, index) => {
return (
<div className='card-bodysingle' key={index}>
<div className='input-div-social'>
<label>Facebook Link :</label>
<input type='text' id='storeFacebook' placeholder='Enter facebook link...' defaultValue={data.fb} />
</div>
<div className='input-div-social'>
<label>Instagram Link :</label>
<input type='text' id='storeInstagram' placeholder='Enter instagram link...' defaultValue={data.insta} />
</div>
<div className='input-div-social'>
<label>Twitter Link :</label>
<input type='text' id='storeTwitter' placeholder='Enter twitter link...' defaultValue={data.twitt} />
</div>
<div className='input-div-social'>
<label>YouTube Link :</label>
<input type='text' id='storeYouTube' placeholder='Enter youtube link...' defaultValue={data.ytube} />
</div>
<div className='input-div-submit'>
<button onClick={() => updateSocial()}><i className='fa fa-edit'></i> Update Social</button>
</div>
</div>
)
})
}
</div>

</div>
</div>
</>
)
}

export default SettingSocial;
