import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalData } from '../context/AppContext';

const Header = () => {

const { allOrders, adminData } = GlobalData();
const [newOrder, setNewOrder] = useState([]);
const Navigate = useNavigate();

const showSideLeftMenu = () => {
$('#leftMenuSide').show();   
}

const reduceLength = newOrder.length > 0 && newOrder.reduce((acc, el) => {
const { orderId, orderStatus } = el;
var item = '';
if (orderStatus === 'proccessing'){
item = acc.find((it) => it.orderId === orderId);
item ? item.orderId = orderId : acc.push({orderId});
}
return acc;
},[]);

const noticeOrder = (id) => {
Navigate('/view-orders/'+id);   
}

const logout = async() => {
try {
const sendData = await axios.get('/node-server/logout');
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Logout successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000); 
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
}
} catch (error) {
console.log('backend logout-api error: ' + error); 
}
}

useEffect(() => {
if (allOrders.length > 0){
setNewOrder(allOrders);
}
},[allOrders]);

return (
<>
<div className='header-div'>
<div className='conatiner-md'>
<div className='row-full'>
<div className='row-col'>
<div className='logoWrap'>  
<div className='menuIcon'><img src='/images/menu.gif' alt='menu' onClick={() => showSideLeftMenu()} /></div>  
<div className='logo-div'>
<Link to='/'><img src='/images/logoMain.png' alt='logo' /></Link>
</div>
</div>
</div>

<div className='row-col'>
<div className='menu-icons'>
<div className='menus-icon-row'>
<div className='menus-icon-row-col dropNoticeIcon'>
<img src='/images/notification.gif' alt='icons' />
<span className='reduceSpan'>{reduceLength ? reduceLength.length : 0}</span>
<div className='dropNotice-menu'>
<ul>
{
reduceLength.length > 0 ? reduceLength.map((data2, index2) => {
return (
<li key={index2}>
<div className='noticeWrap'>
<div><img src='../images/new.gif' alt='new' /> <span>Recent Order</span></div>
<div>
<span>Order Id : </span>
<span>{data2.orderId}</span>
<button onClick={() => noticeOrder(data2.orderId)}>View Order</button>
</div>
</div>
</li>
)
})
:
<li>Currently no order available...</li>
}   
</ul>
</div>
</div>
<div className='menus-icon-row-col dropMyAcc'>
<img src='/images/profile.gif' alt='icons' />
<div className='dropNotice-menu-sec'>
<div><span><i className='fa fa-user'></i> {adminData.length > 0 && adminData[0].username}</span></div>
<ul>
<Link to='/user-profile'><li><i className='fa fa-caret-right'></i> Change Password</li></Link>
<li onClick={() => logout()}><i className='fa fa-caret-right'></i> Log Out</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Header;
