import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const EditCategoryModal = () => {
const closeEdit = () => {
$('#editCategoryModal').hide();  
}

const updateCategory = async() => {
var id =  $('#editCatId').val();   
var name =  $('#editCatName').val();  
var types = $('#editCatType').val();

if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Category name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(types === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Item type required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/node-server/update-category', {id: id, name: name, types: types});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Category updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend update-category-api error: ' + error);  
}
}
}

return (
<>
<div className='modals' id='editCategoryModal'>
<div className='modals-content'>
<div className='modals-haeder'><i className='fa fa-edit'></i> Edit Category <i className='fa fa-close rightModalIcon' onClick={() => closeEdit()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<img id='editCatImg' alt='editcatimg' />
<div className='input-div'>
<label>Category Name</label>
<input type='hidden' id='editCatId' />
<input type='text' id='editCatName' placeholder='Enter category name...' />
</div>
<div className='input-div'>
<label>Item Type</label>
<select id='editCatType'>
<option value='null'>---select item type---</option>
<option value='veg'>Veg</option>
<option value='non veg'>Non Veg</option>
</select>
</div>
<div className='input-div-submit'>
<button onClick={() => updateCategory()}>Edit Category</button>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default EditCategoryModal;
