import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const RestoreOrder = () => {

const closeRemove = () => {
$('#removeOrderItem').hide();
}

const restoreItemOrder = async() => {
var id = $('#removeId').val();
try {
const sendData = await axios.post('/node-server/restore-order', {id: id});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Order restored successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');  
},1000);
}
} catch (error) {
console.log('backend restor-order-api error: ' + error); 
}  
}

return (
<>
<div className='modals' id='removeOrderItem'>
<div className='modals-content-remove'>
<div className='modals-haeder'><i className='fa fa-history'></i> Restore Order <i className='fa fa-close rightModalIcon' onClick={() => closeRemove()}></i></div>

<div className='modals-body'>
<div className='body-content'>
<center><img src='../images/backup.gif' alt='trash' id='editCatImg' /></center>
<input type='hidden' id='removeId' />
<span>Are you sure? You want to restore this order?</span>
<div className='yesNoDiv'>
<button onClick={() => restoreItemOrder()}>Yes</button>
<button onClick={() => closeRemove()}>No</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default RestoreOrder;
